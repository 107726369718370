import React, { useState, useContext, useEffect } from "react";
import { Box, Button, Text, Input, Icon } from "@chakra-ui/react";
import { OrderContext } from "../../contexts/Order";
import { AiOutlineDislike, AiOutlineLike } from "react-icons/ai";
import { LuDownloadCloud } from "react-icons/lu";

export default function ArticleExpert() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [savedInfos, setSavedInfos] = useState([]);
  const [categories, setCategories] = useState("");
  const { feedbackCategoriesApi } = useContext(OrderContext);

  async function getCategoriesApi(id) {
    try {
      const response = await feedbackCategoriesApi(id);
      console.log(response.data?.[0]);
      setCategories(response.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  // const handleDescriptionChange = (content) => {
  //     setDescription(content);
  // };

  const saveInfo = () => {
    setSavedInfos([...savedInfos, { title: title, description: description }]);
    setTitle("");
    setDescription("");
  };

  return (
    <>
      {savedInfos.map((info, index) => (
        <Box
          key={index}
          bg={"#ffff"}
          borderRadius={"15px "}
          boxShadow={"0px 7px 16px 4px #EAEAEA"}
          p={5}
          width={"910px"}
          ml={"250px"}
          mt={"50px"}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            borderBottom={"1px"}
            borderColor={"#D2D1D166"}
          >
            <Text fontSize={"20px"}>Artigos Provatis </Text>
          </Box>
          <Box
            mt={"20px"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          ></Box>
          <Box
            mt={"20px"}
            mb={"15px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text fontSize={"24px"}>{info.title}</Text>
            <Box background={"black"} borderRadius={"200px"} padding={1}>
              <LuDownloadCloud
                color="white"
                fontSize={"24px"}
                cursor={"pointer"}
              />
            </Box>
          </Box>

          {/*
                    <Box display={'flex'} mt={'20px'} alignItems={'center'} gap={1}>
                        <Text fontSize={'16px'} color={'#888686'}>Essa dica foi útil?</Text>
                        <AiOutlineLike fontSize={'16px'} color={'#888686'} />
                        <Text fontSize={'16px'} color={'#888686'}>Sim</Text>
                        <AiOutlineDislike fontSize={'16px'} color={'#888686'} />
                        <Text fontSize={'16px'} color={'#888686'}>Não</Text>
                        
                    </Box>
                    */}
        </Box>
      ))}
    </>
  );
}
