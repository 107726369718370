import React, { useState, useContext, useEffect } from "react";
import JoditEditor from "../../components/Editor";
import {
  useToast,
  Box,
  Button,
  Text,
  Input,
  Stack,
  FormControl,
  Tabs,
  Tab,
  TabList,
  AspectRatio,
  Select,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import { OrderContext } from "../../contexts/Order";
import { ProlabContext } from "../../contexts/Prolab";
import { Badge, IconButton } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import {
  AiOutlineDislike,
  AiOutlineLike,
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineSave,
} from "react-icons/ai";
import ReactSelect from "react-select";

export default function VideoProvatis() {
  const toast = useToast();
  const [title, setTitle] = useState("");
  const [video, setVideo] = useState("");
  const {
    listVideosProlabApi,
    createVideoProlabApi,
    updateVideoApi,
    deleteVideoApi,
    deleteSecondaryCategoryVideoApi,
  } = useContext(ProlabContext);
  const [description, setDescription] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [editingVideoId, setEditingVideoId] = useState(null);
  const [categories, setCategories] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [categoryId, setCategoryId] = useState(1);
  const { feedbackCategoriesApi } = useContext(OrderContext);
  const [videos, setVideos] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [tempTitle, setTempTitle] = useState("");
  const [tempDescription, setTempDescription] = useState("");
  const [tempLink, setTempLink] = useState("");
  const [selectedCategories, setSelectedCategories] = useState();
  const [options, setOptions] = useState([]);
  const [selectedSecondaryCategories, setSelectedSecondaryCategories] =
    useState([]);

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      zIndex: 9999, // Ajuste o valor conforme necessário
    }),
  };

  function limitToTwoWords(text) {
    const words = text.split(" ");
    if (words.length > 2) {
      return words.slice(0, 2).join(" ") + "..."; // Adiciona '...' se tiver mais de 2 palavras
    }
    return text;
  }
  const handleEditClick = (video) => {
    setVideo(video.video);
    setCategoryId(video.category_id)
    setIsEditing(true);
    setTempTitle(video.title);
    setTempDescription(video.description);
    const initialSecondaryCategories = video.tags.map((tag) => ({
      category_id: tag.id, 
      category_name: tag.category_name
    }));
    setSelectedSecondaryCategories(initialSecondaryCategories)
    setEditingVideoId(video.id);
  };

  const deleteSecondaryCategoryVideo = async (id) => {
    try {
      const response = await deleteSecondaryCategoryVideoApi(id);
      if (response.status === 200) {
        toast({
          title: "Tag deletada com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        listVideo();
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Erro ao deletar a pílula",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSaveClick = async () => {
    setVideo("");
    const updatedVideo = {
      id: editingVideoId,
      learning_videos: tempTitle,
      description: tempDescription,
      video: video,
      category_id: selectedCategory,
      secondary_categories: selectedSecondaryCategories,
    };

    try {
      const response = await updateVideoApi(editingVideoId, updatedVideo);
      if (response.status === 200) {
        const updatedVideos = videos.map((video) => {
          if (video.id === editingVideoId) {
            return updatedVideo;
          }
          return video;
        });
        toast({
          title: "Vídeo atualizado com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        listVideo();
      }
    } catch (error) {
      toast({
        title: "Erro ao atualizar vídeo",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.log(error);
    }

    setIsEditing(false);
    setEditingVideoId(null);
  };

  const deleteVideo = async (videoId) => {
    try {
      const response = await deleteVideoApi(videoId);
      if (response.status === 200) {
        const updatedVideos = videos.filter((video) => video.id !== videoId);
        setVideos(updatedVideos);
        toast({
          title: "Vídeo excluído com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        listVideo();
      }
    } catch (error) {
      toast({
        title: "Erro ao excluir vídeo",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.log(error);
    }
  };

  async function listVideo() {
    try {
      const response = await listVideosProlabApi(categoryId);
      console.log(response);
      setVideos(response.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  async function getCategoriesApi(id) {
    try {
      const response = await feedbackCategoriesApi(id);
      const categoriesOrdered = [...response.data?.[0]]?.sort((a, b) => {
        const nameA = a.category?.toLowerCase() || ''; 
        const nameB = b.category?.toLowerCase() || '';
        return nameA.localeCompare(nameB); 
      });
      console.log(response.data?.[0]);
      setCategories(categoriesOrdered);
      setOptions(
        categoriesOrdered?.map((index) => ({
          value: index.id,
          label: index.category,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  }

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleDescriptionChange = (content) => {
    setDescription(content);
  };
  const handleVideoChange = (event) => {
    setVideo(event.target.value);
    console.log(event);
  };

  const handleEditClickClose = (pill) => {
    setIsEditing(false);
  };
  const saveInfo = async () => {
    const data = {
      title: title,
      video: video,
      description: description,
      category_id: selectedCategory,
      secondary_categories: selectedSecondaryCategories,
    };
    try {
      const response = await createVideoProlabApi(data);
      console.log(response);
      setTitle("");
      setVideo("");
      setDescription("");

      if (response.status === 200) {
        // Faça algo com a resposta do servidor
        toast({
          title: "Vídeo adicionado com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        listVideo();
      }
      setCategories("");
      setTitle("");
      setDescription("");

      setTimeout(() => {
        getCategoriesApi();
      }, 1000); // Ag

      listVideo();
    } catch (error) {
      console.log(error);
    }
  };

  async function listVideoCategory(id) {
    try {
      const response = await listVideosProlabApi(id);
      console.log(response);
      setVideos(response.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  const handleCategoryClick = async (category) => {
    setSelectedCategory(category);
    setCategoryId(category);
    setCategoryName(category.category);

    listVideoCategory(category.id);

    try {
      const response = await listVideosProlabApi(category.id);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeSecondaryCategories = (selectedOptions) => {
    const formattedOptions = selectedOptions.map((option) => ({
      category_id: option.value,
    }));

    // Atualize o estado 'selectedSecondaryCategories' com os dados formatados
    setSelectedSecondaryCategories(formattedOptions);

    // Exiba os dados formatados no console
    console.log(formattedOptions);
  };

  const handleChangeSecondaryCategoriesUpdate = (selectedOptions) => {
    const formattedOptions = selectedOptions.map((option) => ({
      category_id: option.value,
      category_name: option.label, 
    }));

    setSelectedSecondaryCategories(formattedOptions);
  };

  useEffect(() => {
    setTimeout(() => {
      getCategoriesApi();
    }, 1000); // Ag

    listVideo();
  }, []);
  return (
    <>
      {categories && (
        <>
          <Tabs
            variant="soft-rounded"
            colorScheme="blackAlpha"
            backgroundColor="white"
          >
            <Box
              justifyContent={"center"}
              mt={"370px"}
              w={"250px"}
              display={"block"}
              position={"absolute"}
              left={"-15px"}
              backgroundColor={"#ffff"}
              p={8}
              borderRadius={"15px "}
              boxShadow={"0px 7px 16px 4px #EAEAEA"}
            >
              <Text>Categorias</Text>

              <TabList display={"block"} borderBottom={"0"}>
                {categories.map((index) => (
                  <Tab
                    mt={"1.5px"}
                    fontSize={"14px"}
                    _hover={{ backgroundColor: "black", color: "white" }}
                    _selected={{ backgroundColor: "black", color: "white" }}
                    onClick={() => handleCategoryClick(index)}
                  >
                    {" "}
                    {limitToTwoWords(index.category)}
                  </Tab>
                ))}
              </TabList>
            </Box>
          </Tabs>

          <Box
            bg={"#ffff"}
            borderRadius={"15px"}
            boxShadow={"0px 7px 16px 4px #EAEAEA"}
            w={"57vw"}
            p={5}
            position={"absolute"}
            ml={"250px"}
            mt={"100px"}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <h1 fontSize="md">Adicionar vídeo</h1>
              <Button onClick={saveInfo}>Salvar</Button>
            </Box>
            <Box mt={"20px"} mb={"20px"}>
              <Text>Adicione o link do vídeo</Text>
              <Input
                defaultValue=""
                type="text"
                placeholder={"Digite Aqui"}
                value={video}
                onChange={handleVideoChange}
              />
            </Box>

            <Box>
              <Text>Título</Text>
              <Input
                placeholder={"Digite Aqui"}
                value={title}
                onChange={handleTitleChange}
              />
            </Box>

            <Box mt={"20px"}>
              <Text>Selecione a Categoria:</Text>
              <Select
                value={selectedCategory}
                onChange={handleCategoryChange}
                placeholder="Selecione a categoria"
              >
                {categories.map((index) => (
                  <option key={index.id} value={index.id}>
                    {index.category}
                  </option>
                ))}
              </Select>
            </Box>
            <Box mt={"20px"}>
              <Text>Selecione as categorias secundárias:</Text>
              <ReactSelect
                id="categoryReactSelect"
                isMulti
                options={options}
                onChange={handleChangeSecondaryCategories}
                styles={customStyles}
              />
            </Box>

            <Stack margin="15px 0">
              <Text fontSize="md">Descrição</Text>
            </Stack>
            <FormControl>
              <JoditEditor
                onBlur={handleDescriptionChange}
                value={description}
              />
            </FormControl>
          </Box>

          <Box marginTop={"800px"}>
            {videos &&
              videos.map((index) => (
                <Box
                  key={index.id}
                  bg={"#ffff"}
                  borderRadius={"15px "}
                  boxShadow={"0px 7px 16px 4px #EAEAEA"}
                  p={5}
                  w={"75%"}
                  ml={"250px"}
                  mt={"20px"}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    borderBottom={"1px"}
                    borderColor={"#D2D1D166"}
                  >
                    <Text fontSize={"20px"}>Vídeo Provatis</Text>
                    <Box
                      m={5}
                      gap={3}
                      display={"flex"}
                      justifyContent={"end"}
                      alignItems={"center"}
                    >
                      {isEditing && editingVideoId === index.id ? (
                        <>
                          <AiOutlineSave
                            cursor={"pointer"}
                            fontSize={"20px"}
                            onClick={handleSaveClick}
                          />
                          <AiOutlineEdit
                            cursor={"pointer"}
                            fontSize={"20px"}
                            onClick={() => handleEditClickClose(index)}
                          />
                        </>
                      ) : (
                        <>
                          <AiOutlineEdit
                            cursor={"pointer"}
                            fontSize={"20px"}
                            onClick={() => handleEditClick(index)}
                          />
                          <AiOutlineDelete
                            cursor={"pointer"}
                            fontSize={"20px"}
                            color="red"
                            onClick={() => {
                              deleteVideo(index.id);
                            }}
                          />
                        </>
                      )}
                    </Box>
                  </Box>

                  <Box
                    mt={"20px"}
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"flex-start"}
                  >
                    <Text
                      p={2}
                      borderRadius={"20px"}
                      background={"black"}
                      color={"white"}
                      fontSize={"14px"}
                    >
                      {index.category_name}{" "}
                    </Text>
                    <Box ml={2}>
                      {index?.tags?.map((tag) => (
                        <Badge
                          key={tag.id} // Supondo que 'id' seja um identificador único para cada tag
                          colorScheme="green"
                          borderRadius="full"
                          mt={2}
                          mr={2}
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          px={2}
                          py={1}
                        >
                          <span>{tag.category_name}</span>
                          <IconButton
                            icon={<CloseIcon />}
                            size="xs"
                            colorScheme="red"
                            ml={2}
                            onClick={() => deleteSecondaryCategoryVideo(tag.id)}
                          />
                        </Badge>
                      ))}
                    </Box>
                  </Box>
                  <Box mt={"20px"} mb={"15px"}>
                    {isEditing && editingVideoId === index.id ? (
                      <>
                        <Text>Adicione o Titulo</Text>
                        <Input
                          value={tempTitle}
                          onChange={(value) => setTempTitle(value)}
                        />
                      </>
                    ) : (
                      <Text fontSize={"24px"}>{index.learning_videos}</Text>
                    )}
                  </Box>

                  <Box mb={"15px"}>
                    {isEditing && editingVideoId === index.id ? (
                      <>
                        <Text>Selecione a Categoria:</Text>
                        <Select
                          defaultValue={categoryId}
                          value={categoryId} 
                          onChange={(value) =>
                            setSelectedCategories(value)
                          }
                          placeholder="Selecione a categoria"
                        >
                          {categories.map((category) => (
                            <option key={category.id} value={category.id}>
                              {category.category}
                            </option>
                          ))}
                        </Select>

                        <Box mt={"20px"}>
                          <Text>Selecione as categorias secundárias:</Text>
                          <ReactSelect
                            id="categoryReactSelect"
                            value={selectedSecondaryCategories.map(tag => ({
                              value: tag.category_id,
                              label: tag.category_name,
                            }))}
                            isMulti
                            options={options}
                            onChange={handleChangeSecondaryCategoriesUpdate}
                            styles={customStyles}
                          />
                        </Box>
                      </>
                    ) : (
                      ""
                    )}
                  </Box>
                  <Box>
                    {isEditing && editingVideoId === index.id ? (
                      <JoditEditor
                        value={tempDescription}
                        onChange={(value) => setTempDescription(value)}
                      />
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: index.description,
                        }}
                      />
                    )}
                  </Box>

                  {isEditing && editingVideoId === index.id ? (
                    <>
                      <Text>Adicione aqui o link do Video</Text>
                      <Input
                        value={video}
                        onChange={(value) => setVideo(value)}
                        placeholder="Link"
                      />
                    </>
                  ) : (
                    <AspectRatio ratio={16 / 9}>
                      <iframe src={index.video} allowFullScreen />
                    </AspectRatio>
                  )}

                  {/*
                    <Box display={'flex'} mt={'20px'} alignItems={'center'} gap={1}>
                        <Text fontSize={'16px'} color={'#888686'}>Essa dica foi útil?</Text>
                        <AiOutlineLike fontSize={'16px'} color={'#888686'} />
                        <Text fontSize={'16px'} color={'#888686'}>Sim</Text>
                        <AiOutlineDislike fontSize={'16px'} color={'#888686'} />
                        <Text fontSize={'16px'} color={'#888686'}>Não</Text>
                        
                    </Box>
                    */}
                </Box>
              ))}
          </Box>
        </>
      )}
    </>
  );
}
