import React, { useState } from "react";
import api from "../services/api";

export const PlanContext = React.createContext({});

export const PlanProvider = ({ children }) => {

    const listPlansApi = async () => {
        const token = localStorage.getItem("token");
        const response = await api.get('/list/plans', {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
        return response;
    }

    return (
        <PlanContext.Provider value={{ listPlansApi }}>
            {children}
        </PlanContext.Provider>
    )
}
