import React, { useContext, useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Flex,
  Input,
  Text,
  Spacer,
  useDisclosure,
  Button,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/react";
import { OrderContext } from "../../contexts/Order";
import { LuPiggyBank } from "react-icons/lu";

export default function Payments() {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const { listOrdersApi, listOrdersExpertApi } = useContext(OrderContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [orders, setOrders] = useState([]);
  const [orderPreview, setOrderPreview] = useState();
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  async function listOrders(page) {
    try {
      const ordersApi = await listOrdersApi(page);
      setOrders(ordersApi?.data?.[0]?.data || []);
      setLastPage(ordersApi?.data?.[0]?.last_page || 1);
    } catch (error) {
      console.log(error);
    }
  }

  async function listOrdersExpert(page) {
    try {
      const ordersApi = await listOrdersExpertApi(page);
      setOrders(ordersApi?.data?.[0]?.data || []);
      setLastPage(ordersApi?.data?.[0]?.last_page || 1);
      console.log(orders);
    } catch (error) {
      console.log(error);
    }
  }
  function handleOrderPage(index) {
    localStorage.setItem("orderID", index.orderID);
    if (user.role_id === 1) {
      navigate(`/servico/${index.title}`);
    } else {
      navigate(`/expert/servico/${index.title}`);
    }
  }

  function previewOrder(index) {
    if (isPreviewing) {
      return;
    }

    setIsPreviewing(true);
    setOrderPreview(index);
    console.log(index);

    onOpen();

    setTimeout(() => {
      setIsPreviewing(false);
    }, 1000); // Aguardar 1 segundo antes de permitir uma nova chamada
  }
  useEffect(() => {
    if (user.role_id === 1) {
      listOrders(currentPage);
    }
    if (user.role_id === 2) {
      listOrdersExpert(currentPage);
    }

    // Delay: Set a delay before making the next request
    const delay = 1000; // 1 second
    const timer = setTimeout(() => {
      if (currentPage < lastPage) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }, delay);

    // Clean up the timer on component unmount
    return () => clearTimeout(timer);
  }, [currentPage]);

  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  }

  function goToNextPage() {
    if (currentPage < lastPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }

  return (
    <>
      <Navbar />

      <Container position={"absolute"} top={120} ml={270} minWidth={"78vw"}>
        <Box
          bg={"#ffff"}
          borderRadius={"15px"}
          boxShadow={"0px 7px 16px 4px #EAEAEA"}
        >
          <Flex display={"flex"} padding={5} alignItems="center">
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <LuPiggyBank fontSize={"20px"} />
              <Text fontSize={"20px"} fontWeight={"600"}>
                Pagamentos
              </Text>
            </Box>
            <Spacer />

            <Box>
              <Button
                fontSize={"14px"}
                bg={"#00FF94"}
                _hover={{
                  backgroundColor: "#33BA9D",
                }}
              >
                <Link to="/pagamentos"> Voltar para pagamentos </Link>
              </Button>
            </Box>
          </Flex>
        </Box>

        <Box
          backgroundColor={"white"}
          mt={5}
          boxShadow={"0px 8px 16px -10px"}
          borderRadius={"8px"}
          marginBottom={5}
        >
          <Box p={"20px"} borderBottom={"1px"} borderColor={"#DBDBDB"}>
            <Text>Enviar documentos para pagamento</Text>
          </Box>
          <Box p={"20px"}>
            <FormControl marginBottom={"15px"}>
              <FormLabel fontSize={"12px"} fontWeight={400}>
                Pagamento referente a qual serviço
              </FormLabel>
              <Select placeholder="Selecione o serviço">
                <option>Lorem ipsum dolor sit</option>
                <option>Lorem ipsum dolor sit</option>
              </Select>
            </FormControl>
            <Box display={"flex"} gap={2}>
              <FormControl marginBottom={"15px"}>
                <FormLabel fontSize={"12px"} fontWeight={400}>
                  Qual tipo de pagamento?
                </FormLabel>
                <Select placeholder="Selecione o serviço">
                  <option>MEI</option>
                  <option>CLT</option>
                </Select>
              </FormControl>
              <FormControl marginBottom={"15px"}>
                <FormLabel fontSize={"12px"} fontWeight={400}>
                  Qual tipo de nota?
                </FormLabel>
                <Select placeholder="Selecione o serviço">
                  <option>Novo serviço</option>
                  <option>Outro</option>
                </Select>
              </FormControl>
            </Box>
            <Box display={"flex"} gap={2}>
              <FormControl>
                <FormLabel fontSize={"12px"} fontWeight={400} color={"#505050"}>
                  Data de conclusão
                </FormLabel>
                <Input />
              </FormControl>
              <FormControl>
                <FormLabel fontSize={"12px"} fontWeight={400} color={"#505050"}>
                  Valor do serviço
                </FormLabel>
                <Input />
              </FormControl>
            </Box>
            <FormControl marginBottom={"15px"}>
              <FormLabel fontSize={"12px"} fontWeight={400}>
                Documento - Envie arquivos nas extensões de PDF.
              </FormLabel>
              <Input type="file"></Input>
            </FormControl>

            <Button
              fontSize={"14px"}
              bg={"#00FF94"}
              _hover={{
                backgroundColor: "#33BA9D",
              }}
            >
              Enviar para pagamento
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
}
