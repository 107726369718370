import React, { useContext, useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import {
  Box,
  Flex,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
} from "@chakra-ui/react";
import DetailsOrder from "../../components/Order/DetailOrder";
import StepsOrder from "../../components/Order/StepOrder";
import { OrderContext } from "../../contexts/Order";
import DocumentsOrder from "../../components/Order/DocumentsOrder";
import FeedbackOrder from "../../components/Order/FeedbackOrder";
import { Link, useParams } from "react-router-dom";

export default function Order() {
  const { getOrderInfo } = useContext(OrderContext);
  const [order, setOrder] = useState();
  const [stepBox, setStepBox] = useState(false);
  const [detailsBox, setDetailBox] = useState(false);
  const { type, id: orderID } = useParams();
 
  async function getOrderInfos() {
    const orderID = localStorage.getItem("orderID");
    try {
      const orderInfoApi = await getOrderInfo(orderID);
      // console.log(orderInfoApi);
      setOrder(orderInfoApi.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
      localStorage.setItem("orderID", orderID);
      console.log("Order ID atualizado:", orderID);
  }, [orderID]);

  useEffect(() => {
    getOrderInfos();
  }, []);
  return (
    <>
      <Navbar />

      {order && (
        <Box
          ml={{
            base: 0, // Remove a margem em telas menores (base)
            md: 300, // Define a margem em telas maiores (md)
          }}
          maxWidth={"100%"}
        >
          <Box p={2}>
            <Tabs variant="soft-rounded" colorScheme="blackAlpha">
              <Box
                mt={4}
                display={"block"}
                position={{
                  base: "block", // Remove a margem em telas menores (base)
                  md: "absolute", // Define a margem em telas maiores (md)
                }}
                backgroundColor={"#ffff"}
                ml={{
                  base: 0, // Remove a margem em telas menores (base)
                  md: "-20px", // Define a margem em telas maiores (md)
                }}
                p={8}
                borderRadius={"15px "}
                boxShadow={"0px 7px 16px 4px #EAEAEA"}
              >
                <TabList display={"block"} borderBottom={"0"}>
                  <Tab
                    marginBlock={"1rem"}
                    _hover={{ backgroundColor: "black", color: "white" }}
                    _selected={{ backgroundColor: "black", color: "white" }}
                  >
                    Detalhes
                  </Tab>
                  <Tab
                    marginBlock={"1rem"}
                    _hover={{ backgroundColor: "black", color: "white" }}
                    _selected={{ backgroundColor: "black", color: "white" }}
                  >
                    Documentos
                  </Tab>
                  {type === "geral" ? (
                    <Tab
                      marginBlock={"1rem"}
                      _hover={{ backgroundColor: "black", color: "white" }}
                      _selected={{ backgroundColor: "black", color: "white" }}
                    >
                      Feedbacks
                    </Tab>
                  ) : (
                    <></>
                  )}
                </TabList>
                <Box marginBlock={"1rem"}>
                  <Button margin="2%">
                    <Link to="/servicos">Voltar</Link>
                  </Button>
                </Box>
              </Box>

              <TabPanels>
                <TabPanel>
                  <DetailsOrder typeOrder={type} />
                </TabPanel>
                <TabPanel>
                  <DocumentsOrder typeOrder={type} order={order} />
                </TabPanel>
                {type === "geral" ? (
                  <TabPanel>
                    <FeedbackOrder />
                  </TabPanel>
                ) : (
                  <></>
                )}
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
      )}
    </>
  );
}
