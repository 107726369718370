import React from "react";

import { AuthProvider } from "./Auth";
import { UserProvider } from "./User";
import { OrderProvider } from "./Order";
import { PlanProvider } from "./Plan";
import { ProlabProvider } from "./Prolab";
import { PaymentProvider } from "./Payments";

export const AppProvider = ({ children }) => {
  return (
    <AuthProvider>
      <UserProvider>
        <PlanProvider>
          <OrderProvider>
            <ProlabProvider>
              <PaymentProvider>{children}</PaymentProvider>
            </ProlabProvider>
          </OrderProvider>
        </PlanProvider>
      </UserProvider>
    </AuthProvider>
  );
};
