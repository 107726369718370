import React, { useState } from "react";
import api from "../services/api";

export const PaymentContext = React.createContext({});

export const PaymentProvider = ({ children }) => {
  const listPaymentsApi = async () => {
    const token = localStorage.getItem("token");
    const response = await api.get("list/payments", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };
  const paymentInfoApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`show/payment/user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const updatePaymentApi = async (id, data) => {
    const token = localStorage.getItem("token");
    const response = await api.put(`update/info/payment/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  return (
    <PaymentContext.Provider
      value={{
        listPaymentsApi,
        paymentInfoApi,
        updatePaymentApi,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
