import { useState, useContext, useEffect } from "react";
import { Box, Text, Input } from "@chakra-ui/react";
import { BsChat } from "react-icons/bs";
import { LuSend } from "react-icons/lu";
import { OrderContext } from "../../contexts/Order";

export default function ChatExpert() {
  const [currentMessage, setCurrentMessage] = useState("");
  const { listMessageChatApi, sendMessageChatApi } = useContext(OrderContext);
  const [sentByMe, setSentByMe] = useState();
  const [sentByOthers, setSentByOthers] = useState();
  async function getMessegeApi() {
    const orderID = localStorage.getItem("orderID");
    try {
      const response = await listMessageChatApi(orderID);
      setSentByMe(response.data?.[0]?.sent_by_me);
      setSentByOthers(response.data?.[0]?.sent_by_others);
    } catch (error) {
      console.log(error);
    }
  }

  const handleMessageChange = (event) => {
    setCurrentMessage(event.target.value);
  };

  const handleSendMessage = async () => {
    const orderID = localStorage.getItem("orderID");
    const data = {
      message: currentMessage,
    };
    try {
      const response = await sendMessageChatApi(orderID, data);
      console.log(response);
      setCurrentMessage("");
      getMessegeApi();
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  useEffect(() => {
    getMessegeApi();
  }, []);

  return (
    <>
      <Box
        bg={"#ffff"}
        borderRadius={"15px "}
        boxShadow={"0px 7px 16px 4px #EAEAEA"}
        p={5}
        width={"80%"}
        height={"80%"}
        marginLeft={"auto"}
      >
        <Box
          display={"flex"}
          gap={1}
          alignItems={"center"}
          color={"#04D194"}
          borderBottom={"1px"}
          borderColor={"#D2D1D166"}
          paddingBottom={"15px"}
          mb={"20px"}
        >
          <BsChat />
          <Text>Chat Expert</Text>
        </Box>

        <Box>
          <Box
            background={"#F3F4FA"}
            minHeight={"250px"}
            width={"100%"}
            height={"100%"}
            marginBottom={"20px"}
            borderRadius={"8px"}
            padding={"15px"}
          >
            {sentByOthers?.map((index) => (
              <Box
                borderRadius={"8px"}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
              >
                <Text
                  background={"White"}
                  borderRadius={"8px"}
                  minWidth={"150px"}
                  padding={2}
                  margin={1}
                  boxShadow={" #00000014"}
                  fontSize={"14px"}
                  fontWeight={"400"}
                >
                  {index.body}
                </Text>
                <Text fontSize={"10px"} textAlign={"left"}>
                  {index.permission}
                </Text>
              </Box>
            ))}

            {sentByMe?.map((index) => (
              <Box
                borderRadius={"8px"}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"end"}
                justifyContent={"end"}
              >
                <Text
                  color={"white"}
                  background={"black"}
                  borderRadius={"8px"}
                  padding={2}
                  margin={1}
                  boxShadow={" #00000014"}
                  fontSize={"14px"}
                  fontWeight={"400"}
                  minWidth={"150px"}
                >
                  {index.body}
                </Text>
                <Text fontSize={"10px"}>{index.permission}</Text>
              </Box>
            ))}
          </Box>
        </Box>

        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Input
            background={"#F3F4FA"}
            type="text"
            value={currentMessage}
            onChange={handleMessageChange}
            onKeyDown={handleKeyDown}
            width={"90%"}
          />
          <Box paddingRight={"20px"} onClick={handleSendMessage}>
            <LuSend fontSize={"30px"} cursor={"pointer"} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
