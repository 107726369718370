import React, { useState, useEffect, useContext, useRef } from "react";
import api from "../../services/api";
import { environment } from "../../environment";
import {
  Box,
  Icon,
  Input,
  Select,
  Text,
  Button,
  Link,
  Stack,
  Flex,
  useToast,
  Tooltip
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { OrderContext } from "../../contexts/Order";
import { CloseIcon } from "@chakra-ui/icons";

export default function DocumentsOrderExpert({ typeOrder, order }) {
  const { getOrderInfo, approveOrderApi, deleteDocumentClient, deleteDocumentExpert} = useContext(OrderContext);
  const toast = useToast();
  const [inputs, setInputs] = useState([]);
  const [docsTradutor, setDocsTradutor] = useState(null);
  const [docsRevisor, setDocsRevisor] = useState(null);
  const [docsEditor, setDocsEditor] = useState(null);
  const [docsClient, setDocsClient] = useState(null);
  const [docsEditNorms, setDocsEditNorms] = useState(null);
  const [FeedbackDocument, setFeedbackDocument] = useState(null);
  const [userID, setUserID] = useState(null);
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
  };
  const handleDeleteDocumentClient = async (fileID) => {
    const mensagem = "Tem certeza que deseja excluir o arquivo?";
    const confirmacao = window.confirm(mensagem);
    const orderID = localStorage.getItem("orderID");
    if(confirmacao){
      try {
        const file = await deleteDocumentClient(orderID, fileID);
        toast({
          title: "Documento deletado com sucesso!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        getDocumentsOrder();
      } catch (error) {
        console.error(error);
        toast({
          title: "Não foi possível deletar o documento. Tente novamente.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  }
  
  const handleDeleteDocumentExpert = async (fileID) => {
    const orderID = localStorage.getItem("orderID");
    const mensagem = "Tem certeza que deseja excluir o arquivo?";
    const confirmacao = window.confirm(mensagem);
    if(confirmacao){
      try {
        const file = await deleteDocumentExpert(orderID, fileID);
        toast({
          title: "Documento deletado com sucesso!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        getDocumentsOrder();
      } catch (error) {
        console.error(error);
        toast({
          title: "Não foi possível deletar o documento. Tente novamente.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  }

  async function getOrderDetails() {
    const orderID = localStorage.getItem("orderID");

    try {
      const orderInfoApi = await getOrderInfo(orderID);
      setUserID(orderInfoApi.data?.[0]?.user_id);
      console.log(orderInfoApi.data?.[0]?.user_id);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getOrderDetails();
  }, []);

  async function getDocumentsOrder() {
    const orderID = localStorage.getItem("orderID");
    const token = localStorage.getItem("token");
    try {
      const response = await api.get(`orders/${orderID}/documents/list`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setDocsClient(response.data?.[1]?.["client"]);
      setDocsTradutor(response.data?.[1]?.["tradutor"]);
      setDocsRevisor(response.data?.[1]?.["revisor"]);
      setDocsEditor(response.data?.[1]?.["editor"]);
      setFeedbackDocument(response.data?.[1]?.["feedback"]);
      setDocsEditNorms(response.data?.[1]?.["edit-norms"]);

    } catch (error) {
      console.error(error);
    }
  }

  const handleDocumentSubmit = async () => {
    const orderID = localStorage.getItem("orderID");
    const token = localStorage.getItem("token");
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    formData.append("order_id", orderID);
    formData.append("user_id", userID);

    try {
      const response = await api.post(`orders/${orderID}/documents/client/upload-file`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      toast({
        title: "Upload feito com sucesso!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setFiles([]);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }

      getDocumentsOrder();
    } catch (error) {
      console.error(error);
      toast({
        title: "Não foi possível enviar o documento. Tente novamente.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const truncateText = (text) => {
    if (text.length > 20) {
      return text.substring(0, 20) + "...";
    }
    return text;
  };

  const verifyShowEditNorms = () => {
    return !!(
      docsEditNorms && (typeOrder === "edicao-normas" || order.magazine_edit)
    );
  };

  useEffect(() => {
    getDocumentsOrder();
  }, []);

  return (
    <>
      <Box
        bg={"#ffff"}
        borderRadius={"15px "}
        boxShadow={"0px 7px 16px 4px #EAEAEA"}
        p={5}
        ml={{
          base: 0, // Remove a margem em telas menores (base)
          md: "180px", // Define a margem em telas maiores (md)
        }}
        maxWidth={"100%"}
      >
        <Flex pb={5} borderBottom={"1px solid #D9D9D9"}>
          <Box>Documentos cliente</Box>
        </Flex>
        {docsClient && (
          <Box mt={5} p={5} maxWidth={"100%"}>
            {docsClient?.map((index) => (
              <Box mt={2} fontSize={"14px"}>
                <Box display={"inline-flex"}>
                  <Text fontWeight={"light"}>Documento: </Text>
                  <Link
                    ml={2}
                    fontWeight={"bold"}
                    href={
                      environment.baseURL + "/storage/uploads/clients/" +
                      index.filename
                    }
                    target="_blank"
                  >
                    {truncateText(index.filename)}
                  </Link>
                  <Tooltip label="Excluir arquivo" fontSize="sm" hasArrow>
                    <Icon
                      as={CloseIcon}
                      ml={3}
                      color="red.500"
                      cursor="pointer"
                      boxSize={3}
                      onClick={() => handleDeleteDocumentClient(index.id)}
                      _hover={{ color: "red.700" }}
                    />
                  </Tooltip>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>

      <Box
        mt={5}
        bg={"#ffff"}
        borderRadius={"15px"}
        boxShadow={"0px 7px 16px 4px #EAEAEA"}
        p={5}
        ml={{ base: 0, md: "180px" }}
        maxWidth={"100%"}
      >
        <Stack>
          <Text>Adicionar Arquivos</Text>
        </Stack>
        <Box marginTop={"25px"}>
          <Input
            pt={2}
            fontSize={"12px"}
            type="file"
            accept=".pdf, .doc, .docx"
            multiple
            ref={fileInputRef}
            onChange={handleFileChange}
          />
        </Box>
        <Box mt={5}>
          <Button onClick={handleDocumentSubmit}>Enviar</Button>
        </Box>
      </Box>
      {docsTradutor && typeOrder === "geral" && (
        <Box
          mt={5}
          bg={"#ffff"}
          borderRadius={"15px "}
          boxShadow={"0px 7px 16px 4px #EAEAEA"}
          p={5}
          ml={{
            base: 0, // Remove a margem em telas menores (base)
            md: "180px", // Define a margem em telas maiores (md)
          }}
          maxWidth={"100%"}
        >
          Documentos Tradutor
          {docsTradutor?.map((index) => (
            <Box mt={2} fontSize={"14px"}>
              <Box display={"inline-flex"}>
                <Text fontWeight={"light"}>Documento: </Text>
                <Link
                  ml={2}
                  fontWeight={"bold"}
                  href={
                    index.filename.startsWith("uploads/expert/")
                      ? environment.baseURL + "/" +
                      index.filename
                      : environment.baseURL + "/storage/uploads/expert/" +
                      index.filename
                  }
                >
                  {truncateText(index.filename)}
                </Link>
              </Box>

              <Box display={"inline-flex"} ml={5}>
                <Text fontWeight={"light"}>Versão: </Text>
                <Text ml={2} fontWeight={"bold"}>
                  {" "}
                  {index.version}
                </Text>
              </Box>

              <Box display={"inline-flex"} ml={5}>
                <Text fontWeight={"light"}>Subtipo: </Text>
                <Text ml={2} fontWeight={"bold"}>
                  {" "}
                  {index.subtype}
                </Text>
              </Box>
              <Tooltip label="Excluir arquivo" fontSize="sm" hasArrow>
                 <Icon
                  as={CloseIcon}
                  ml={3}
                  color="red.500"
                  cursor="pointer"
                  boxSize={3}
                  onClick={() => handleDeleteDocumentExpert(index.id)}
                  _hover={{ color: "red.700" }}
                  />
               </Tooltip>
            </Box>
          ))}
        </Box>
      )}
      {docsRevisor && typeOrder === "geral" && (
        <Box
          mt={5}
          bg={"#ffff"}
          borderRadius={"15px "}
          boxShadow={"0px 7px 16px 4px #EAEAEA"}
          p={5}
          ml={{
            base: 0, // Remove a margem em telas menores (base)
            md: "180px", // Define a margem em telas maiores (md)
          }}
          maxWidth={"100%"}
        >
          Documentos Revisor
          {docsRevisor?.map((index) => (
            <Box mt={2} fontSize={"14px"}>
              <Box display={"inline-flex"}>
                <Text fontWeight={"light"}>Documento: </Text>
                <Link
                  ml={2}
                  fontWeight={"bold"}
                  href={
                    index.filename.startsWith("uploads/expert/")
                      ? environment.baseURL + "/" +
                      index.filename
                      : environment.baseURL + "/storage/uploads/expert/" +
                      index.filename
                  }
                >
                  {truncateText(index.filename)}
                </Link>
              </Box>

              <Box display={"inline-flex"} ml={5}>
                <Text fontWeight={"light"}>Versão: </Text>
                <Text ml={2} fontWeight={"bold"}>
                  {" "}
                  {index.version}
                </Text>
              </Box>

              <Box display={"inline-flex"} ml={5}>
                <Text fontWeight={"light"}>Subtipo: </Text>
                <Text ml={2} fontWeight={"bold"}>
                  {" "}
                  {index.subtype}
                </Text>
              </Box>
              <Tooltip label="Excluir arquivo" fontSize="sm" hasArrow>
                 <Icon
                  as={CloseIcon}
                  ml={3}
                  color="red.500"
                  cursor="pointer"
                  boxSize={3}
                  onClick={() => handleDeleteDocumentExpert(index.id)}
                  _hover={{ color: "red.700" }}
                  />
               </Tooltip>
            </Box>
          ))}
        </Box>
      )}
      {docsEditor && typeOrder === "geral" && (
        <Box
          mt={5}
          bg={"#ffff"}
          borderRadius={"15px "}
          boxShadow={"0px 7px 16px 4px #EAEAEA"}
          p={5}
          ml={{
            base: 0, // Remove a margem em telas menores (base)
            md: "180px", // Define a margem em telas maiores (md)
          }}
          maxWidth={"100%"}
        >
          Documentos Editor
          {docsEditor?.map((index) => (
            <Box mt={2} fontSize={"14px"}>
              <Box display={"inline-flex"}>
                <Text fontWeight={"light"}>Documento: </Text>
                <Link
                  ml={2}
                  fontWeight={"bold"}
                  href={
                    index.filename.startsWith("uploads/expert/")
                      ? environment.baseURL + "/" +
                      index.filename
                      : environment.baseURL + "/storage/uploads/expert/" +
                      index.filename
                  }
                >
                  {truncateText(index.filename)}
                </Link>
              </Box>

              <Box display={"inline-flex"} ml={5}>
                <Text fontWeight={"light"}>Versão: </Text>
                <Text ml={2} fontWeight={"bold"}>
                  {" "}
                  {index.version}
                </Text>
              </Box>

              <Box display={"inline-flex"} ml={5}>
                <Text fontWeight={"light"}>Subtipo: </Text>
                <Text ml={2} fontWeight={"bold"}>
                  {" "}
                  {index.subtype}
                </Text>
              </Box>
              <Tooltip label="Excluir arquivo" fontSize="sm" hasArrow>
                 <Icon
                  as={CloseIcon}
                  ml={3}
                  color="red.500"
                  cursor="pointer"
                  boxSize={3}
                  onClick={() => handleDeleteDocumentExpert(index.id)}
                  _hover={{ color: "red.700" }}
                  />
               </Tooltip>
            </Box>
          ))}
        </Box>
      )}
      {FeedbackDocument && typeOrder === "geral" && (
        <Box
          mt={5}
          bg={"#ffff"}
          borderRadius={"15px "}
          boxShadow={"0px 7px 16px 4px #EAEAEA"}
          p={5}
          ml={{
            base: 0, // Remove a margem em telas menores (base)
            md: "180px", // Define a margem em telas maiores (md)
          }}
          maxWidth={"100%"}
        >
          Documentos de Feedback
          {FeedbackDocument?.map((index) => (
            <Box mt={2} fontSize={"14px"}>
              <Box display={"inline-flex"}>
                <Text fontWeight={"light"}>Documento: </Text>
                <Link
                  ml={2}
                  fontWeight={"bold"}
                  href={
                    index.filename.startsWith("uploads/expert/")
                      ? environment.baseURL + "/" +
                      index.filename
                      : environment.baseURL + "/storage/uploads/expert/" +
                      index.filename
                  }
                >
                  {truncateText(index.filename)}
                </Link>
              </Box>

              <Box display={"inline-flex"} ml={5}>
                <Text fontWeight={"light"}>Subtipo: </Text>
                <Text ml={2} fontWeight={"bold"}>
                  {" "}
                  {index.subtype}
                </Text>
              </Box>
            </Box>
          ))}
        </Box>
      )}
      {verifyShowEditNorms() && (
        <Box
          mt={5}
          bg={"#ffff"}
          borderRadius={"15px "}
          boxShadow={"0px 7px 16px 4px #EAEAEA"}
          p={5}
          ml={{
            base: 0, // Remove a margem em telas menores (base)
            md: "180px", // Define a margem em telas maiores (md)
          }}
          maxWidth={"100%"}
        >
          Documentos Editor de Normas
          {docsEditNorms?.map((index) => (
            <Box mt={2} fontSize={"14px"}>
              <Box display={"inline-flex"}>
                <Text fontWeight={"light"}>Documento: </Text>
                <Link
                  ml={2}
                  fontWeight={"bold"}
                  href={
                    index.filename.startsWith("uploads/expert/")
                      ? environment.baseURL + "/" +
                      index.filename
                      : environment.baseURL + "/storage/uploads/expert/" +
                      index.filename
                  }
                >
                  {truncateText(index.filename)}
                </Link>
                <Tooltip label="Excluir arquivo" fontSize="sm" hasArrow>
                    <Icon
                      as={CloseIcon}
                      ml={3}
                      color="red.500"
                      cursor="pointer"
                      boxSize={3}
                      onClick={() => handleDeleteDocumentExpert(index.id)}
                      _hover={{ color: "red.700" }}
                    />
                </Tooltip>
              </Box>

              <Box display={"inline-flex"} ml={5}>
                <Text fontWeight={"light"}>Versão: </Text>
                <Text ml={2} fontWeight={"bold"}>
                  {" "}
                  {index.version}
                </Text>
              </Box>

              <Box display={"inline-flex"} ml={5}>
                <Text fontWeight={"light"}>Subtipo: </Text>
                <Text ml={2} fontWeight={"bold"}>
                  {" "}
                  {index.subtype}
                </Text>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
}
