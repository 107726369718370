import React, { useContext, useState, useEffect } from "react";
import Moment from "react-moment";

import {
  Box,
  Input,
  Flex,
  FormControl,
  FormLabel,
  Text,
  Grid,
  GridItem,
  Button,
} from "@chakra-ui/react";
import { BiTimeFive } from "react-icons/bi";
import { OrderContext } from "../../contexts/Order";
export default function StandardsDetails() {
  const { getEditionServiceApi } = useContext(OrderContext);

  const [editionDetails, setEditionDetails] = useState();

  async function getEditionDetails() {
    const editionID = localStorage.getItem("editionID");

    try {
      const orderInfoApi = await getEditionServiceApi(editionID);
      setEditionDetails(orderInfoApi.data?.[0]);
      console.log(orderInfoApi.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getEditionDetails();
  }, []);
  return (
    editionDetails && (
      <>
        <Box
          bg={"#ffff"}
          borderRadius={"15px "}
          boxShadow={"0px 7px 16px 4px #EAEAEA"}
          p={5}
          ml={"240px"}
          w={"70%"}
        >
          <Box pb={5}>
            <Box>
              <Text fontWeight={400} fontSize={"20px"} color={"#171F23"}>
                Sobre a edição
              </Text>{" "}
            </Box>
            <Flex p={3} gap={6} m={3}>
              <Box>
                <Text fontWeight={500} fontSize={"12px"} color={"#05070B"}>
                  Normas da revista
                </Text>
                <Text fontWeight={400} fontSize={"14px"} color={"#242424"}>
                  {editionDetails?.name_magazine}
                </Text>
              </Box>
              <Box>
                <Text fontWeight={500} fontSize={"12px"} color={"#05070B"}>
                  Número de referências
                </Text>
                <Text fontWeight={400} fontSize={"14px"} color={"#242424"}>
                  {editionDetails?.references}
                </Text>
              </Box>
            </Flex>
          </Box>
        </Box>
        <Box
          bg={"#ffff"}
          borderRadius={"15px "}
          boxShadow={"0px 7px 16px 4px #EAEAEA"}
          p={5}
          mt={5}
          ml={"240px"}
          w={"70%"}
        >
          <Box pb={5}>
            <Box p={5}>
              <Text fontWeight={400} fontSize={"20px"} color={"#171F23"}>
                Valor e Prazos
              </Text>{" "}
            </Box>
            <Flex p={3} gap={6} m={3}>
              <Box>
                <Text fontWeight={500} fontSize={"12px"} color={"#05070B"}>
                  Valor final do Expert
                </Text>
                <Text fontWeight={400} fontSize={"14px"} color={"#242424"}>
                  {editionDetails?.value_to_expert}
                </Text>
              </Box>
              <Box>
                <Text fontWeight={500} fontSize={"12px"} color={"#05070B"}>
                  Prazo final
                </Text>
                <Text fontWeight={400} fontSize={"14px"} color={"#242424"}>
                  {editionDetails?.days_to_expert}
                </Text>
              </Box>
              <Box>
                <Text fontWeight={500} fontSize={"12px"} color={"#05070B"}>
                  Data de entrega
                </Text>
                <Text fontWeight={400} fontSize={"14px"} color={"#242424"}>
                  {editionDetails?.deadline}
                </Text>
              </Box>
            </Flex>
          </Box>
        </Box>
      </>
    )
  );
}
