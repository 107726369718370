

export const environment = {
    //baseURL: "http://localhost/api/",
    baseAPI: process.env.REACT_APP_BASE_API,
    baseURL: process.env.REACT_APP_BASE_URL,
    // baseAPI: "https://api-homologacao.srv.provatis.academy/api",
    // baseURL: "https://api-homologacao.srv.provatis.academy",
    //baseURL: "https://api-homologacao.srv.provatis.academy/api"
    //baseURL: "https://api-provatis.provatis.academy/api/",
    //baseURL: "https://hml-api.provatis.academy/public/api",
};