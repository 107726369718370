import React, { useState, useEffect, useContext } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Icon,
  Avatar,
  Container,
  Flex,
  Text,
  AspectRatio,
  Button,
} from "@chakra-ui/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  FreeMode,
  Virtual,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/pagination";
import "../../components/Slider/Slider.css";

import { TbHandRock } from "react-icons/tb";
import { HiOutlineLightningBolt } from "react-icons/hi";
import { LuFlaskConical } from "react-icons/lu";
import { IoTimeOutline, IoChevronForward } from "react-icons/io5";
import Logo from "../../images/avatar.jpg";
import { FaFlask } from "react-icons/fa";
import { LuLightbulb, LuFlaskRound } from "react-icons/lu";
import { ProlabContext } from "../../contexts/Prolab";

export default function Home() {
  const { showPillProlabApi, showPillRandomProlabApi } =
    useContext(ProlabContext);
  const { listVideosProlabApi } = useContext(ProlabContext);
  const navigate = useNavigate();
  const [user, setUser] = useState(() => JSON.parse(localStorage.getItem("user")));
  const [userHistoric, setUserHistoric] = useState(null);
  const [pills, setPills] = useState("");
  const [videos, setVideos] = useState("");
  const [minScoreCategory, setMinScoreCategory] = useState();

  function limitToTwoWords(text) {
    const words = text.split(" ");
    if (words.length > 2) {
      return words.slice(0, 2).join(" ") + "..."; // Adiciona '...' se tiver mais de 2 palavras
    }
    return text;
  }

  function getExpertInfo() {
    const userInfo = JSON.parse(localStorage.getItem("user"));

    setUser(userInfo);
    setUserHistoric(userInfo.historic);
    if (userInfo.historic && userInfo.historic.length > 0) {
      setUserHistoric(userInfo.historic);
    }
  }

  function handleOrderPage(index) {
    localStorage.setItem("orderID", index.id);
    if (user.role_id === 1) {
      navigate(`/servico/${index.id}/geral`);
    } else {
      navigate(`/expert/servico/${index.id}/geral`);
    }
  }

  async function showPill(id) {
    try {
      const response = await showPillRandomProlabApi(id);
      setPills(response.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }
  async function listVideos(id) {
    try {
      const response = await listVideosProlabApi(id);
      console.log(response.data);
      setVideos(response.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }
  async function fetchData () {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    const updateMinScoreCategory = () => {
      if (userInfo?.min_score_category_id === 0) {
        setMinScoreCategory((prevMinScoreCategory) => {
          const newCategory = Math.floor(Math.random() * 8) + 1;
          showPill(newCategory);
          listVideos(newCategory);
          return newCategory;
        });
      } else {
        setMinScoreCategory(userInfo?.min_score_category_id);
        showPill(userInfo?.min_score_category_id);
        listVideos(userInfo?.min_score_category_id);
      }
    };

    updateMinScoreCategory();
    getExpertInfo();
    navigate(`/home/${encodeURIComponent(userInfo.name)}`, { replace: true });
  }
  useEffect(() => {
    fetchData();
  }, [localStorage.getItem("user")]);
  return (
    <>
      <Navbar />
      {user && (
        <Container
          position={"absolute"}
          top={150}
          ml={300}
          minWidth={"80vw"}
          pb={8}
        >
          <Box
            boxShadow={"0px 11px 20px -9px rgba(0,0,0,0.1)"}
            width={"90%"}
            borderRadius={"8px"}
          >
            <Box
              backgroundColor={"#2AD67E"}
              minHeight={"80px"}
              borderRadius={"8px 8px 8px 8px"}
            >
              <Flex alignItems={"center"} flexDirection={"row"}>
                <Box padding={5}>
                  <Avatar left={"45px"} size={"lg"} src={Logo} />
                </Box>
                <Box ml={"100px"} mt={"14px"} fontSize={"16px"}>
                  <Text>
                    Bem Vindo,<br></br>
                  </Text>
                  <Text fontWeight={"bold"}>{user.name}</Text>
                </Box>
                <Box mt={4}>
                  <Box
                    backgroundColor={"white"}
                    marginLeft={5}
                    width={"35px"}
                    height={"35px"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    borderRadius={"50%"}
                  >
                    <Icon color={"#2AD67E"} fontSize={22}>
                      <TbHandRock />
                    </Icon>
                  </Box>
                </Box>
              </Flex>
            </Box>
          </Box>

          {user.expert.expert_test === 1 && (
            <Box p={4}>
              <Text fontSize={16} color={"#2AD67E"} fontWeight={"bold"}>
                Você está em fase de avaliação.
              </Text>
              <Text fontSize={14} color={"#2AD67E"}>
                Restam {user?.remainingOrders} serviço(s).
              </Text>
              <Box
                borderRadius={5}
                overflow="hidden"
                style={{ width: "90%", height: "20px", position: "relative" }}
              >
                <Box
                  as="div"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: `${(user?.remainingOrders / 3) * 100}%`,
                    height: "100%",
                    borderRadius: "5px",
                    backgroundColor: "#2AD67E",
                  }}
                />
              </Box>
            </Box>
          )}

          <Box display={"flex"}>
            <Box display={"block"} width={"62% "}>
              <Box
                marginTop={"15px"}
                backgroundColor={"white"}
                boxShadow={"0px 11px 20px -9px rgba(0,0,0,0.1)"}
                borderRadius={"8px"}
              >
                <Flex
                  alignItems={"center"}
                  p={5}
                  justifyContent={"space-between"}
                  borderBottom={"1px solid rgba(210, 209, 209, 0.4)"}
                >
                  <Box display={"inline-flex"} alignItems={"center"}>
                    <LuLightbulb color={"#2AD67E"} fontSize={"24px"} />
                    <Text ml={5}>Última Pílula Expert</Text>
                  </Box>
                  <Box
                    display={"inline-flex"}
                    alignItems={"center"}
                    justifyContent={"end"}
                  >
                    <Link to={"/prolab"}>
                      <Text color={"#2AD67E"}>Ir para o Prolab&gt; </Text>
                    </Link>
                  </Box>
                </Flex>
                <Box p={15}>
                  <Box display={"flex"}>
                    <Box ml={"20px"}>
                      <Text>{pills?.learning_pills}</Text>
                    </Box>
                  </Box>
                  <Box>
                    <Text>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: pills?.description,
                        }}
                      />
                    </Text>
                  </Box>
                </Box>
              </Box>

              <Box
                marginTop={"15px"}
                backgroundColor={"white"}
                boxShadow={"0px 11px 20px -9px rgba(0,0,0,0.1)"}
                borderRadius={"8px"}
              >
                <Flex
                  alignItems={"center"}
                  p={5}
                  justifyContent={"space-between"}
                  borderBottom={"1px solid rgba(210, 209, 209, 0.4)"}
                >
                  <Box display={"inline-flex"} alignItems={"center"}>
                    <LuFlaskRound color={"#2AD67E"} fontSize={"24px"} />
                    <Text ml={5}>Últimos vídeos ProLab</Text>
                  </Box>
                  <Box
                    display={"inline-flex"}
                    alignItems={"center"}
                    justifyContent={"end"}
                  >
                    <Link to={"/prolab"}>
                      <Text color={"#2AD67E"}>Ir para o Prolab&gt; </Text>
                    </Link>
                  </Box>
                </Flex>
                <Box p={2}>
                  <Box ml={"20px"}>
                    <Text>{videos?.category_id}</Text>
                  </Box>

                  <Swiper
                    modules={[Pagination, Navigation]}
                    spaceBetween={50}
                    slidesPerView={3}
                    pagination={{ clickable: true }}
                    navigation={true}
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log("slide change")}
                  >
                    {videos &&
                      videos?.map((index) => (
                        <>
                          <SwiperSlide>
                            <AspectRatio maxW="250px" ratio={1} m={"3px"}>
                              <iframe src={index.video} />
                            </AspectRatio>
                          </SwiperSlide>
                        </>
                      ))}
                  </Swiper>
                </Box>
              </Box>
            </Box>
            <Box
              backgroundColor={"white"}
              ml={"3%"}
              mt={5}
              boxShadow={"0px 11px 20px -9px rgba(0,0,0,0.1)"}
              width={"25%"}
              borderRadius={"8px"}
              height={"70%"}
            >
              <Flex display={"block"} alignItems={"end"}>
                <Box
                  backgroundColor={"#2AD67E"}
                  width={"100%"}
                  height={"90px"}
                  borderTopRadius={"8px"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Flex
                    justifyContent={"center"}
                    alignItems={"end"}
                    backgroundColor={"white"}
                    display={"flex"}
                    borderRadius={"100%"}
                    border={"1px"}
                    borderColor={"#E7E9F4"}
                  >
                    <Box padding={"20px"} color={"#8389AA"} fontSize={"30px"}>
                      <LuFlaskConical />
                    </Box>
                  </Flex>
                </Box>
                {user.actual_service != null ? (
                  <>
                    <Box m={"20px"}>
                      <Text
                        p={"5px"}
                        backgroundColor={"#853AFF"}
                        borderRadius={"100px"}
                        color={"white"}
                        fontSize={"14px"}
                        fontWeight={600}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        {userHistoric?.status}
                      </Text>
                      <Text
                        marginTop={"15px"}
                        fontSize={"16px"}
                        fontWeight={500}
                      >
                        {user.actual_service?.title}
                      </Text>
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        marginTop={"10px"}
                      >
                        <Box
                          backgroundColor={"black"}
                          borderRadius={"100px"}
                          p={"5px"}
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Icon color={"#2AD67E"} fontSize={20}>
                            <HiOutlineLightningBolt />
                          </Icon>
                          <Text
                            fontSize={"12px"}
                            fontWeight={600}
                            color={"white"}
                          >
                            {user.actual_service?.plan_id === 1
                              ? "Basic"
                              : user.actual_service?.plan_id === 2
                              ? "PRO"
                              : user.actual_service?.plan_id === 3
                              ? "FULL"
                              : null}
                          </Text>
                        </Box>
                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Icon color={"#2AD67E"} fontSize={20}>
                            <FaFlask />
                          </Icon>
                          <Text fontSize={"16px"} fontWeight={500}>
                            {user.actual_service?.type_document}
                          </Text>
                        </Box>
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        marginTop={"20px"}
                        borderRadius={"100px"}
                        backgroundColor={"#F5F6FF"}
                      >
                        <Icon color={"#2AD67E"} fontSize={20}>
                          <IoTimeOutline />
                        </Icon>
                        <Text color={"#565656"} fontSize={"12px"}>
                          {" "}
                          <b>Prazo Final:</b> {userHistoric?.deadline}
                        </Text>
                      </Box>

                      <Box
                        marginTop={"20px"}
                        borderTop={"1px"}
                        borderColor={"#D2D1D1"}
                      >
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"5px"}
                        >
                          <Text fontSize={"14px"} fontWeight={300}>
                            Fast Delivery
                          </Text>
                          <Text fontSize={"14px"} fontWeight={500}>
                            {user.actual_service?.fast_delivery == 0
                              ? "Não"
                              : "Sim"}
                          </Text>
                        </Box>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"5px"}
                        >
                          <Text fontSize={"14px"} fontWeight={300}>
                            Idioma origem
                          </Text>
                          <Text fontSize={"14px"} fontWeight={500}>
                            {user.actual_service?.language_origin === "br" ||
                            user.actual_service?.language_origin === "pt"
                              ? "Português"
                              : "Inglês"}
                          </Text>
                        </Box>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"5px"}
                        >
                          <Text fontSize={"14px"} fontWeight={300}>
                            Idioma final
                          </Text>
                          <Text fontSize={"14px"} fontWeight={500}>
                            {user.actual_service?.language_final === "br" ||
                            user.actual_service?.language_final === "pt"
                              ? "Português"
                              : "Inglês"}
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      m={"20px"}
                    >
                      <Button
                        colorScheme="green"
                        onClick={() => handleOrderPage(user.actual_service)}
                      >
                        {" "}
                        Ir para serviço
                      </Button>
                    </Box>
                  </>
                ) : (
                  <Text p={8}> Sem Serviço em Andamento</Text>
                )}
              </Flex>
            </Box>
          </Box>
        </Container>
      )}
    </>
  );
}
