import React, { useContext, useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { format } from "date-fns";
import {
  Box,
  Flex,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
} from "@chakra-ui/react";
import { LiaListAltSolid } from "react-icons/lia";
import { LuFileInput } from "react-icons/lu";

import StandardsDetails from "../../components/Order/StandardsDetails";
import StandardsDocumentsExpert from "../../components/Order/StandardsDocumentsExpert";

export default function DetailEditionExpert() {
  useEffect(() => {}, []);

  return (
    <>
      <Navbar />

      <Box ml={300} maxWidth={"1000px"}>
        <Box p={2}>
          <Tabs colorScheme="green">
            <Box
              mt={4}
              display={"block"}
              position={"absolute"}
              left={"-45px"}
              backgroundColor={"#ffff"}
              p={8}
              borderRadius={"15px "}
              boxShadow={"0px 7px 16px 4px #EAEAEA"}
            >
              <Text m={2}>Guia do serviço</Text>
              <TabList display={"block"} borderBottom={"0"} colorScheme="green">
                <Tab gap={1}>
                  <LiaListAltSolid />
                  Detalhes do Serviço
                </Tab>
                <Tab gap={1}>
                  <LuFileInput />
                  Documentos
                </Tab>
              </TabList>
            </Box>

            <TabPanels>
              <TabPanel>
                <StandardsDetails />
              </TabPanel>
              <TabPanel>
                <StandardsDocumentsExpert />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
    </>
  );
}
