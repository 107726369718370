import React, { useState, useContext, useEffect } from "react";
import JoditEditor from "../../components/Editor";
import {
  useToast,
  Box,
  Button,
  Text,
  Input,
  Stack,
  FormControl,
  Tabs,
  Tab,
  TabList,
  Select,
  Badge,
  Tooltip,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import { OrderContext } from "../../contexts/Order";
import { ProlabContext } from "../../contexts/Prolab";
import ReactSelect from "react-select";
import {
  AiOutlineDislike,
  AiOutlineLike,
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineSave,
} from "react-icons/ai";
import { IconButton } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

export default function TeachingPills() {
  const toast = useToast();
  const [title, setTitle] = useState("");
  const {
    listPillsProlabApi,
    createPillProlabApi,
    updatePillApi,
    deletePillApi,
    deleteSecondaryCategoryPillApi,
  } = useContext(ProlabContext);
  const [description, setDescription] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [editingPillId, setEditingPillId] = useState(null);
  const [categories, setCategories] = useState("");
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedCategoryUpdate, setSelectedCategoryUpdate] = useState();
  const [categoryId, setCategoryId] = useState(1);
  const { feedbackCategoriesApi } = useContext(OrderContext);
  const [pills, setPills] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [tempTitle, setTempTitle] = useState("");
  const [tempDescription, setTempDescription] = useState("");
  const [selectedCategories, setSelectedCategories] = useState();
  const [options, setOptions] = useState([]);
  const [selectedSecondaryCategories, setSelectedSecondaryCategories] =
    useState([]);
  const [tags, setTags] = useState();

  function limitToTwoWords(text) {
    const words = text.split(" ");
    if (words.length > 2) {
      return words.slice(0, 2).join(" ") + "..."; // Adiciona '...' se tiver mais de 2 palavras
    }
    return text;
  }

  const handleEditClick = (pill) => {
    setIsEditing(true);
    setTempTitle(pill.learning_pills);
    setTempDescription(pill.description);
    setEditingPillId(pill.id);
  };

  const handleEditClickClose = (pill) => {
    setIsEditing(false);
  };

  const handleSaveClick = async () => {
    const updatedPill = {
      id: editingPillId,
      pill: tempTitle,
      description: tempDescription,
      category_id: selectedCategories,
      secondary_categories: selectedSecondaryCategories,
    };

    try {
      const response = await updatePillApi(editingPillId, updatedPill);
      if (response.status === 200) {
        const updatedPills = pills.map((pill) => {
          if (pill.id === editingPillId) {
            return updatedPill;
          }
          return pill;
        });
        setPills(updatedPills);
        toast({
          title: "Pílula atualizada com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        listPills();
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Erro ao atualizar a pílula",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    setIsEditing(false);
    setEditingPillId(null);
  };

  const deletePill = async (pillId) => {
    try {
      const response = await deletePillApi(pillId);
      if (response.status === 200) {
        const updatedPills = pills.filter((pill) => pill.id !== pillId);
        setPills(updatedPills);
        toast({
          title: "Pílula deletada com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        listPills();
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Erro ao deletar a pílula",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const deleteSecondaryCategoryPill = async (id) => {
    try {
      const response = await deleteSecondaryCategoryPillApi(id);
      if (response.status === 200) {
        toast({
          title: "Tag deletada com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
      listPills();
    } catch (error) {
      console.log(error);
      toast({
        title: "Erro ao deletar a pílula",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  async function listPills() {
    try {
      const response = await listPillsProlabApi(categoryId);
      console.log(response);
      setPills(response.data?.[0]);
      setSelectedCategory(0);
      selectedSecondaryCategories(0);
      setSelectedSecondaryCategories([]);
      setSelectedCategories([]);
    } catch (error) {
      console.log(error);
    }
  }

  async function getCategoriesApi(id) {
    try {
      const response = await feedbackCategoriesApi(id);
      const categoriesOrdered = [...response.data?.[0]]?.sort((a, b) => {
        const nameA = a.category?.toLowerCase() || ''; 
        const nameB = b.category?.toLowerCase() || '';
        return nameA.localeCompare(nameB); 
      });
      console.log(response.data?.[0]);
      setCategories(categoriesOrdered);
      setOptions(
        categoriesOrdered?.map((index) => ({
          value: index.id,
          label: index.category,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  }

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleDescriptionChange = (content) => {
    setDescription(content);
  };

  const saveInfo = async () => {
    const data = {
      pill: title,
      description: description,
      category_id: selectedCategory,
      secondary_categories: selectedSecondaryCategories,
    };

    try {
      const response = await createPillProlabApi(data);
      console.log(response);
      setTitle("");
      setDescription("");
      setOptions([]);

      if (response.status === 200) {
        // Faça algo com a resposta do servidor
        toast({
          title: "Pílula criada com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        listPills();
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function listPillsCategory(id) {
    try {
      const response = await listPillsProlabApi(id);
      console.log(response);
      setPills(response.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setCategoryId(category.id);
    setCategoryName(category.category);

    listPillsCategory(category.id);
  };

  const handleChangeSecondaryCategories = (selectedOptions) => {
    const formattedOptions = selectedOptions.map((option) => ({
      category_id: option.value,
    }));

    // Atualize o estado 'selectedSecondaryCategories' com os dados formatados
    setSelectedSecondaryCategories(formattedOptions);

    // Exiba os dados formatados no console
    console.log(formattedOptions);
  };

  const handleChangeSecondaryCategoriesUpdate = (selectedOptions) => {
    const formattedOptions = selectedOptions.map((option) => ({
      category_id: option.value,
    }));

    setSelectedSecondaryCategories(formattedOptions);
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      zIndex: 9999, // Ajuste o valor conforme necessário
    }),
  };

  useEffect(() => {
    setTimeout(() => {
      getCategoriesApi();
    }, 1000); // Ag

    listPills();
  }, []);

  return (
    <>
      {categories && (
        <>
          <Tabs
            variant="soft-rounded"
            colorScheme="blackAlpha"
            backgroundColor="white"
          >
            <Box
              justifyContent={"center"}
              mt={"370px"}
              w={"250px"}
              display={"block"}
              position={"absolute"}
              left={"-15px"}
              backgroundColor={"#ffff"}
              p={8}
              borderRadius={"15px "}
              boxShadow={"0px 7px 16px 4px #EAEAEA"}
            >
              <Text>Categorias</Text>
              <TabList display={"block"} borderBottom={"0"}>
                {categories.map((index) => (
                  <Tab
                    mt={"1.5px"}
                    fontSize={"14px"}
                    _hover={{ backgroundColor: "black", color: "white" }}
                    _selected={{ backgroundColor: "black", color: "white" }}
                    onClick={() => handleCategoryClick(index)}
                  >
                    {limitToTwoWords(index.category)}
                  </Tab>
                ))}
              </TabList>
            </Box>
          </Tabs>

          <Box
            bg={"#ffff"}
            borderRadius={"15px"}
            boxShadow={"0px 7px 16px 4px #EAEAEA"}
            w={"57vw"}
            p={5}
            position={"absolute"}
            ml={"250px"}
            mt={"100px"}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <h1 fontSize="md">Adicionar Pílula</h1>
              <Button onClick={() => saveInfo()}>Salvar</Button>
            </Box>
            <Box>
              <Text>Título</Text>
              <Input
                placeholder={"Digite Aqui"}
                value={title}
                onChange={handleTitleChange}
              />
            </Box>
            <Box mt={"20px"}>
              <Text>Selecione a Categoria:</Text>
              <Select
                value={selectedCategory}
                onChange={handleCategoryChange}
                placeholder="Selecione a categoria"
              >
                {categories.map((index) => (
                  <option key={index.id} value={index.id}>
                    {index.category}
                  </option>
                ))}
              </Select>
            </Box>
            <Box mt={"20px"}>
              <Text>Selecione as categorias secundárias:</Text>
              <ReactSelect
                id="categoryReactSelect"
                isMulti
                options={options}
                onChange={handleChangeSecondaryCategories}
                styles={customStyles}
              />
            </Box>

            <Stack margin="15px ">
              <Text fontSize="md">Descrição</Text>
            </Stack>
            <FormControl>
              <JoditEditor
                onBlur={handleDescriptionChange}
                value={description}
              />
            </FormControl>
          </Box>

          <Box marginTop={"690px"}>
            {pills &&
              pills.map((index) => (
                <Box
                  key={index.id}
                  marginTop={"80px"}
                  margin={"20px"}
                  bg={"#ffff"}
                  borderRadius={"15px "}
                  boxShadow={"0px 7px 16px 4px #EAEAEA"}
                  p={5}
                  width={"75%"}
                  ml={"250px"}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    borderBottom={"1px"}
                    borderColor={"#D2D1D166"}
                  >
                    <Text fontSize={"20px"}>Pílula Expert</Text>

                    <Box
                      m={5}
                      gap={3}
                      display={"flex"}
                      justifyContent={"end"}
                      alignItems={"center"}
                    >
                      {isEditing && editingPillId === index.id ? (
                        <>
                          <AiOutlineSave
                            cursor={"pointer"}
                            fontSize={"20px"}
                            onClick={handleSaveClick}
                          />
                          <AiOutlineEdit
                            cursor={"pointer"}
                            fontSize={"20px"}
                            onClick={() => handleEditClickClose(index)}
                          />
                        </>
                      ) : (
                        <>
                          <AiOutlineEdit
                            cursor={"pointer"}
                            fontSize={"20px"}
                            onClick={() => handleEditClick(index)}
                          />
                          <AiOutlineDelete
                            cursor={"pointer"}
                            fontSize={"20px"}
                            color="red"
                            onClick={() => {
                              deletePill(index.id);
                            }}
                          />
                        </>
                      )}
                    </Box>
                  </Box>

                  <Box
                    mt={"20px"}
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"flex-start"}
                  >
                    <Text
                      p={2}
                      borderRadius={"20px"}
                      background={"black"}
                      color={"white"}
                      fontSize={"14px"}
                    >
                      {index.category_name}{" "}
                    </Text>
                    <Box ml={2}>
                      {index?.tags?.map((tag) => (
                        <Box
                          key={tag.id} // Assuming 'id' is a unique identifier for each tag
                          display="inline-block"
                          bg="green.200"
                          color="green.800"
                          borderRadius="full"
                          p={2}
                          mr={2}
                          position="relative"
                        >
                          {tag.category_name}
                          <IconButton
                            icon={<CloseIcon />}
                            size="sm"
                            color="red.500"
                            position="absolute"
                            top="0"
                            right="0"
                            onClick={() => deleteSecondaryCategoryPill(tag.id)}
                          />
                        </Box>
                      ))}
                    </Box>
                  </Box>

                  <Box mt={"20px"} mb={"15px"}>
                    {isEditing && editingPillId === index.id ? (
                      <Input
                        value={tempTitle}
                        onChange={(e) => setTempTitle(e.target.value)}
                      />
                    ) : (
                      <Text fontSize={"24px"}>{index.learning_pills}</Text>
                    )}
                  </Box>

                  <Box mb={"15px"}>
                    {isEditing && editingPillId === index.id ? (
                      <>
                        <Text>Selecione a Categoria:</Text>
                        <Select
                          onChange={(e) =>
                            setSelectedCategories(e.target.value)
                          }
                          placeholder="Selecione a categoria"
                        >
                          {categories.map((index) => (
                            <option key={index.id} value={index.id}>
                              {index.category}
                            </option>
                          ))}
                        </Select>

                        <Box mt={"20px"}>
                          <Text>Selecione as categorias secundárias:</Text>
                          <ReactSelect
                            id="categoryReactSelect"
                            isMulti
                            options={options}
                            onChange={handleChangeSecondaryCategoriesUpdate}
                            styles={customStyles}
                          />
                        </Box>
                      </>
                    ) : (
                      ""
                    )}
                  </Box>
                  <Box>
                    {isEditing && editingPillId === index.id ? (
                      <JoditEditor
                        value={tempDescription}
                        onChange={(value) => setTempDescription(value)}
                      />
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: index.description,
                        }}
                      />
                    )}
                  </Box>

                  {/*
                    <Box display={'flex'} mt={'20px'} alignItems={'center'} gap={1}>
                        <Text fontSize={'16px'} color={'#888686'}>Essa dica foi útil?</Text>
                        <AiOutlineLike fontSize={'16px'} color={'#888686'} />
                        <Text fontSize={'16px'} color={'#888686'}>Sim</Text>
                        <AiOutlineDislike fontSize={'16px'} color={'#888686'} />
                        <Text fontSize={'16px'} color={'#888686'}>Não</Text>
                        
                    </Box>
                    */}
                </Box>
              ))}
          </Box>
        </>
      )}
    </>
  );
}
