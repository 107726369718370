import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { AppProvider } from "./contexts";
import Router from "./routes";
import { Helmet } from "react-helmet";

function App() {
  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {`
            (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "mgj8e9jwse");
          `}
        </script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-4RJM9BTBKZ"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-4RJM9BTBKZ');
          `}
        </script>
      </Helmet>
      <ChakraProvider>
        <AppProvider>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </AppProvider>
      </ChakraProvider>
    </>
  );
}

export default App;
