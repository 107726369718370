import React, { useContext, useEffect, useState, useRef } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Flex,
  Text,
  Spacer,
  useDisclosure,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import { OrderContext } from "../../contexts/Order";

import { MdEditSquare, MdRemoveRedEye } from "react-icons/md";

export default function EditionsServices() {
  const user = JSON.parse(localStorage.getItem("user"));
  const { listEditionsServicesApi } = useContext(OrderContext);
  const [editionsServices, setEditionsServices] = useState();
  const [fastDelivery, setFastDelivery] = useState("");
  const [fastDeliveryDays, setFastDeliveryDays] = useState("");
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleInputFastDelivery = (e) => setFastDelivery(e.target.value);
  const handleInputFastDeliveryDays = (e) =>
    setFastDeliveryDays(e.target.value);

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  async function listEditionsServices() {
    try {
      const response = await listEditionsServicesApi();
      setEditionsServices(response?.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  function goToEdition(index) {
    localStorage.setItem("editionID", index.id);
    if (user.role_id === 1) {
      navigate(`/edition/service/${index.name_magazine}`);
    } else {
      navigate(`/expert/edition/service/${index.name_magazine}`);
    }
  }

  useEffect(() => {
    listEditionsServices();
  }, []);

  return (
    <>
      <Navbar />

      <Container position={"absolute"} top={120} ml={270} minWidth={"78vw"}>
        <Box
          bg={"#ffff"}
          borderRadius={"15px"}
          boxShadow={"0px 7px 16px 4px #EAEAEA"}
        >
          <Flex display={"flex"} padding={5} alignItems="center">
            <Box>
              <Text fontSize={"24px"} fontWeight={"600"}>
                Edições das Normas da Revista
              </Text>
            </Box>

            <Spacer />

            {user.role_id === 1 ? (
              <>
                <Box>
                  <Button
                    onClick={onOpen}
                    fontSize={"14px"}
                    colorScheme="green"
                  >
                    Adicionar nova edição das normas da revista
                  </Button>
                </Box>
              </>
            ) : (
              ""
            )}
          </Flex>
        </Box>

        <Box mt={2}>
          <Button fontSize={"14px"} colorScheme="green">
            <Link href="/servicos"> Ver todos os servicos </Link>
          </Button>
        </Box>

        <Box
          backgroundColor={"white"}
          mt={5}
          boxShadow={"0px 8px 16px -10px"}
          borderRadius={"8px"}
          marginBottom={5}
        >
          <TableContainer>
            <Table fontSize={"12px"}>
              <Thead css={{ fontFamily: "Poppins", fontSize: "12px" }}>
                <Tr>
                  <Th>ID</Th>
                  <Th>Titulo</Th>
                  <Th>Expert</Th>
                  <Th>Finalizado</Th>
                  <Th>Valor do Serviço</Th>
                </Tr>
              </Thead>
              <Tbody color={"#888686"} fontWeight={"300"}>
                {editionsServices?.map((index) => (
                  <Tr key={index.id}>
                    <Td>{index.id}</Td>
                    <Td>{index.name_magazine}</Td>
                    <Td>{index.name}</Td>
                    <Td>{index.finished === 0 ? "Não" : "Finalizado"}</Td>
                    <Td marginLeft={5}>{index.value}</Td>
                    <Td>
                      <Box display={"inline-flex"} fontSize={"16px"}>
                        <Box
                          cursor={"pointer"}
                          color={"#115EFA"}
                          onClick={() => goToEdition(index)}
                        >
                          <MdEditSquare />
                        </Box>
                      </Box>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Criar edição das normas da resvista</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl>
                <FormLabel>Título</FormLabel>
                <Input ref={initialRef} placeholder="Título" />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>FastDelivery</FormLabel>
                <Select
                  placeholder="FastDelivery"
                  onChange={handleInputFastDelivery}
                >
                  <option value="1">Sim</option>
                  <option value="0">Não</option>
                </Select>

                {fastDelivery === "1" ? (
                  <Input
                    marginTop={"5px"}
                    onChange={handleInputFastDeliveryDays}
                    placeholder="Receber em quantos dias"
                  />
                ) : null}
              </FormControl>
              <FormLabel mt={"15px"}>Referência</FormLabel>
              <Input type="number" />
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="green" mr={3}>
                Salvar
              </Button>
              <Button onClick={onClose}>Cancelar</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Container>
    </>
  );
}
