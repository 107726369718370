import React, { useState, useContext, useEffect } from "react";
import JoditEditor from "../../components/Editor";
import {
  useToast,
  Box,
  Button,
  Text,
  Input,
  Stack,
  FormControl,
  Tabs,
  Tab,
  TabList,
  AspectRatio,
  Select,
  Badge,
} from "@chakra-ui/react";
import { OrderContext } from "../../contexts/Order";
import { ProlabContext } from "../../contexts/Prolab";
import {
  AiOutlineDislike,
  AiOutlineLike,
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineSave,
} from "react-icons/ai";

export default function VideoProvatis() {
  const toast = useToast();
  const [title, setTitle] = useState("");
  const [video, setVideo] = useState("");
  const {
    listVideosProlabApi,
    createVideoProlabApi,
    updateVideoApi,
    deleteVideoApi,
  } = useContext(ProlabContext);
  const [description, setDescription] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [editingVideoId, setEditingVideoId] = useState(null);
  const [categories, setCategories] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(1);
  const [categoryId, setCategoryId] = useState(1);
  const { feedbackCategoriesApi } = useContext(OrderContext);
  const [videos, setVideos] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [tempTitle, setTempTitle] = useState("");
  const [tempDescription, setTempDescription] = useState("");
  const [tempLink, setTempLink] = useState("");

  function limitToTwoWords(text) {
    const words = text.split(" ");
    if (words.length > 2) {
      return words.slice(0, 2).join(" ") + "..."; // Adiciona '...' se tiver mais de 2 palavras
    }
    return text;
  }

  const handleEditClick = (video) => {
    setIsEditing(true);
    setTempTitle(video.learning_pills);
    setTempDescription(video.description);
    setEditingVideoId(video.id);
  };

  const handleSaveClick = async () => {
    const updatedVideo = {
      id: editingVideoId,
      learning_videos: tempTitle,
      description: tempDescription,
      link: tempLink,
    };

    try {
      const response = await updateVideoApi(updatedVideo);
      if (response.status === 200) {
        const updatedVideos = videos.map((video) => {
          if (video.id === editingVideoId) {
            return updatedVideo;
          }
          return video;
        });
        setVideos(updatedVideos);
        toast({
          title: "Vídeo atualizado com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        listVideo();
      }
    } catch (error) {
      toast({
        title: "Erro ao atualizar vídeo",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.log(error);
    }

    setIsEditing(false);
    setEditingVideoId(null);
  };

  const deleteVideo = async (videoId) => {
    try {
      const response = await deleteVideoApi(videoId);
      if (response.status === 200) {
        const updatedVideos = videos.filter((video) => video.id !== videoId);
        setVideos(updatedVideos);
        toast({
          title: "Vídeo excluído com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        listVideo();
      }
    } catch (error) {
      toast({
        title: "Erro ao excluir vídeo",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.log(error);
    }
  };
  async function listVideo() {
    try {
      const response = await listVideosProlabApi(categoryId);
      console.log(response);
      setVideos(response.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  async function getCategoriesApi(id) {
    try {
      const response = await feedbackCategoriesApi(id);
      console.log(response.data?.[0]);
      setCategories(response.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    console.log(event.target.value);
  };

  const handleDescriptionChange = (content) => {
    setDescription(content);
  };
  const handleVideoChange = (event) => {
    setVideo(event.target.value);
    console.log(event);
  };

  const saveInfo = async () => {
    const data = {
      title: title,
      video: video,
      description: description,
      category_id: selectedCategory,
    };
    try {
      const response = await createVideoProlabApi(data);
      console.log(response);
      setTitle("");
      setVideo("");
      setDescription("");

      if (response.status === 200) {
        // Faça algo com a resposta do servidor
        toast({
          title: "Vídeo adicionado com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        listVideo();
      }
      setCategories("");
      setTitle("");
      setDescription("");

      setTimeout(() => {
        getCategoriesApi();
      }, 1000); // Ag

      listVideo();
    } catch (error) {
      console.log(error);
    }
  };

  async function listVideoCategory(id) {
    try {
      const response = await listVideosProlabApi(id);
      console.log(response);
      setVideos(response.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  const handleCategoryClick = async (category) => {
    setSelectedCategory(category);
    setCategoryId(category);
    setCategoryName(category.category);

    listVideoCategory(category.id);

    try {
      const response = await listVideosProlabApi(category.id);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getCategoriesApi();
    }, 1000); // Ag

    listVideo();
  }, []);
  return (
    <>
      {categories && (
        <>
          <Tabs
            variant="soft-rounded"
            colorScheme="blackAlpha"
            backgroundColor="white"
          >
            <Box
              justifyContent={"center"}
              mt={"370px"}
              w={"250px"}
              display={"block"}
              position={"absolute"}
              left={"-15px"}
              backgroundColor={"#ffff"}
              p={8}
              borderRadius={"15px "}
              boxShadow={"0px 7px 16px 4px #EAEAEA"}
            >
              <Text>Categorias</Text>

              <TabList display={"block"} borderBottom={"0"}>
                {categories.map((index) => (
                  <Tab
                    mt={"1.5px"}
                    fontSize={"14px"}
                    _hover={{ backgroundColor: "black", color: "white" }}
                    _selected={{ backgroundColor: "black", color: "white" }}
                    onClick={() => handleCategoryClick(index)}
                  >
                    {" "}
                    {limitToTwoWords(index.category)}
                  </Tab>
                ))}
              </TabList>
            </Box>
          </Tabs>

          <Box marginTop={"110px"}>
            {videos &&
              videos.map((index) => (
                <Box
                  key={index.id}
                  bg={"#ffff"}
                  borderRadius={"15px "}
                  boxShadow={"0px 7px 16px 4px #EAEAEA"}
                  p={5}
                  w={"75%"}
                  ml={"250px"}
                  mt={"20px"}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    borderBottom={"1px"}
                    borderColor={"#D2D1D166"}
                  >
                    <Text fontSize={"20px"}>Vídeo Provatis</Text>
                    <Box
                      m={5}
                      gap={3}
                      display={"flex"}
                      justifyContent={"end"}
                      alignItems={"center"}
                    ></Box>
                  </Box>

                  <Box
                    mt={"20px"}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"flex-start"}
                  >
                    <Text
                      p={2}
                      borderRadius={"20px"}
                      background={"black"}
                      color={"white"}
                      fontSize={"14px"}
                    >
                      {index.category_name}
                    </Text>
                  </Box>
                  <Box>
                    {index?.tags?.map((tag, tagIndex) => (
                      <Badge
                        key={tagIndex}
                        colorScheme="green"
                        borderRadius="full"
                        mt={2}
                        mr={2}
                      >
                        {tag.category_name}
                      </Badge>
                    ))}
                  </Box>
                  <Box mt={"20px"} mb={"15px"}>
                    {isEditing && editingVideoId === index.id ? (
                      <Input
                        value={tempTitle}
                        onChange={(e) => setTempTitle(e.target.value)}
                      />
                    ) : (
                      <Text fontSize={"24px"}>{index.learning_videos}</Text>
                    )}
                  </Box>
                  <Box>
                    {isEditing && editingVideoId === index.id ? (
                      <JoditEditor
                        value={tempDescription}
                        onChange={(value) => setTempDescription(value)}
                      />
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: index.description,
                        }}
                      />
                    )}
                  </Box>

                  {isEditing && editingVideoId === index.id ? (
                    <Input
                      value={tempLink}
                      onChange={(e) => setTempLink(e.target.value)}
                      placeholder="Link"
                    />
                  ) : (
                    <AspectRatio ratio={16 / 9}>
                      <iframe src={index.video} allowFullScreen />
                    </AspectRatio>
                  )}

                  {/*
                    <Box display={'flex'} mt={'20px'} alignItems={'center'} gap={1}>
                        <Text fontSize={'16px'} color={'#888686'}>Essa dica foi útil?</Text>
                        <AiOutlineLike fontSize={'16px'} color={'#888686'} />
                        <Text fontSize={'16px'} color={'#888686'}>Sim</Text>
                        <AiOutlineDislike fontSize={'16px'} color={'#888686'} />
                        <Text fontSize={'16px'} color={'#888686'}>Não</Text>
                        
                    </Box>
                    */}
                </Box>
              ))}
          </Box>
        </>
      )}
    </>
  );
}
