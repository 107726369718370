import React, { useState, useEffect, useContext } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { OrderContext } from "../../contexts/Order";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Icon,
  Avatar,
  Container,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Button,
  SimpleGrid,
} from "@chakra-ui/react";
import { TbHandRock } from "react-icons/tb";
import { format } from "date-fns";
import Logo from "../../images/avatar.jpg";
import { MdEditSquare, MdRemoveRedEye } from "react-icons/md";

export default function Home() {
  const navigate = useNavigate();
  const { listOrdersApi } = useContext(OrderContext);
  const [user, setUser] = useState();
  const [orders, setOrders] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  function limitToTwoWords(text) {
    const words = text.split(" ");
    if (words.length > 2) {
      return words.slice(0, 2).join(" ") + "..."; // Adiciona '...' se tiver mais de 2 palavras
    }
    return text;
  }

  function getExpertInfo() {
    const userInfo = JSON.parse(localStorage.getItem("user"));

    setUser(userInfo);
  }

  async function listOrders(page) {
    try {
      const ordersApi = await listOrdersApi(page);
      setOrders(ordersApi?.data?.[0]?.data || []);
      setLastPage(ordersApi?.data?.[0]?.last_page || 1);
    } catch (error) {
      console.log(error);
    }
  }

  function handleOrderPage(index) {
    localStorage.setItem("orderID", index.orderID);
    if (user.role_id === 1) {
      navigate(`/servico/${index.orderID}`);
    } else {
      navigate(`/expert/servico/${index.orderID}`);
    }
  }

  useEffect(() => {
    getExpertInfo();
    const delay = 1000; // 1 second
    const timerId = setTimeout(() => {
      listOrders(currentPage);
    }, delay);

    // Clear the timeout if the component is unmounted or the page changes
    return () => clearTimeout(timerId);
  }, [currentPage, user]);

  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  }

  function goToNextPage() {
    if (currentPage < lastPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }

  return (
    <>
      <Navbar />

      <Container
        position={"absolute"}
        top={120}
        ml={{ base: 0, md: 300 }}
        minWidth={{ base: 0, md: "75vw" }}
        pb={8}
      >
        <Box
          gap={3}
          p={5}
          display={"flex"}
          alignItems={"center"}
          boxShadow={"0px 11px 20px -9px rgba(0,0,0,0.1)"}
          borderRadius={"8px"}
          backgroundColor={"#2AD67E"}
        >
          <Avatar size={"lg"} src={Logo} />

          <Box fontSize={"16px"}>
            <Text>
              Bem Vindo,<br></br>
            </Text>
            <Text fontWeight={"bold"}>{user?.name}</Text>
          </Box>

          {/* <Icon color={"black"} fontSize={22}>
            <TbHandRock />
          </Icon> */}
        </Box>

        {user && (
          <>
            <Flex>
              <Box
                backgroundColor={"white"}
                mt={5}
                boxShadow={"0px 11px 20px -9px rgba(0,0,0,0.1)"}
                borderRadius={"8px"}
                padding={{ base: "20px", md: "20px" }}
                width={"100%"}
              >
                <Flex
                  alignItems={{ base: "flex-start", md: "center" }}
                  flexDirection={{ base: "column", md: "row" }}
                  justifyContent={"space-between"}
                >
                  <Box marginBottom={{ base: "20px", md: 0 }}>
                    <Text
                      fontSize={{ base: "16px", md: "18px" }}
                      fontWeight={"normal"}
                      marginBottom={{ base: "10px", md: 0 }}
                    >
                      Últimas atividades dos experts
                    </Text>
                  </Box>
                  <Box
                    marginTop={{ base: "20px", md: 0 }}
                    marginLeft={{ md: "auto" }}
                  >
                    <SimpleGrid
                      columns={{ base: 1, md: 3 }}
                      spacing={{ base: "20px", md: "40px" }}
                    >
                      <Box display={"inline-flex"} alignItems={"center"}>
                        <Box
                          backgroundColor={"#04D19421"}
                          marginLeft={5}
                          width={"30px"}
                          height={"30px"}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          borderRadius={"50%"}
                        >
                          <Icon
                            color={"#2AD67E"}
                            fontSize={22}
                            textAlign={"center"}
                          >
                            <TbHandRock />
                          </Icon>
                        </Box>
                        <Box display={"inline-flex"} marginLeft={2}>
                          <Text fontSize={"22px"} fontWeight={"600"}>
                            {user.qtyOrdersProduction}
                          </Text>
                          <Text
                            fontSize={{ base: "10px", md: "14px" }}
                            lineHeight={1}
                            fontWeight={"400"}
                            marginLeft={2}
                          >
                            Serviços<br></br>
                            em andamento
                          </Text>
                        </Box>
                      </Box>
                      <Box display={"flex"} alignItems={"center"}>
                        <Box
                          backgroundColor={"#04D19421"}
                          marginLeft={5}
                          width={"30px"}
                          height={"30px"}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          borderRadius={"50%"}
                        >
                          <Icon
                            color={"#2AD67E"}
                            fontSize={22}
                            textAlign={"center"}
                          >
                            <TbHandRock />
                          </Icon>
                        </Box>
                        <Box display={"inline-flex"} marginLeft={2}>
                          <Text fontSize={"22px"} fontWeight={"600"}>
                            {user.qtyOrders}
                          </Text>
                          <Text
                            fontSize={{ base: "10px", md: "14px" }}
                            lineHeight={1}
                            fontWeight={"400"}
                            marginLeft={2}
                          >
                            Serviços<br></br>
                            Realizados
                          </Text>
                        </Box>
                      </Box>
                      <Box display={"flex"} alignItems={"center"}>
                        <Box
                          backgroundColor={"#04D19421"}
                          marginLeft={5}
                          width={"30px"}
                          height={"30px"}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          borderRadius={"50%"}
                        >
                          <Icon
                            color={"#2AD67E"}
                            fontSize={22}
                            textAlign={"center"}
                          >
                            <TbHandRock />
                          </Icon>
                        </Box>
                        <Box display={"inline-flex"} marginLeft={2}>
                          <Text fontSize={"22px"} fontWeight={"600"}>
                            {user.qtyOrdersDone}
                          </Text>
                          <Text
                            fontSize={{ base: "10px", md: "14px" }}
                            lineHeight={1}
                            fontWeight={"400"}
                            marginLeft={2}
                          >
                            Serviços<br></br>
                            Finalizados
                          </Text>
                        </Box>
                      </Box>
                    </SimpleGrid>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </>
        )}
        <Box
          backgroundColor={"white"}
          mt={5}
          boxShadow={"0px 11px 20px -9px rgba(0,0,0,0.1)"}
          borderRadius={"8px"}
          marginBottom={5}
          width={"100%"}
        >
          <TableContainer>
            <Table fontSize={"12px"}>
              <Thead css={{ fontFamily: "Poppins", fontSize: "12px" }}>
                <Tr>
                  <Th>ID</Th>
                  <Th>Cliente</Th>
                  <Th>Titulo</Th>
                  <Th>Expert</Th>
                  <Th>Status</Th>
                  <Th>Etapa</Th>
                  <Th>Plano</Th>
                  <Th>Tipo de Documento</Th>
                  <Th>Prazo Final</Th>
                </Tr>
              </Thead>
              <Tbody color={"#888686"} fontWeight={"400"}>
                {orders?.map((index) => (
                  <Tr key={index.orderID}>
                    <Td>{index.orderID}</Td>
                    <Td>{index.client}</Td>

                    <Td>{limitToTwoWords(index.title)}</Td>
                    <Td>{index.expert}</Td>
                    <Td>{index.status}</Td>
                    <Td>{index.step}</Td>
                    <Td>{index.plan}</Td>
                    <Td>{index.type_document}</Td>
                    <Td>{format(new Date(index.deadline), "dd/MM/yyyy")}</Td>
                    <Td>
                      <Box display={"inline-flex"} fontSize={"20px"}>
                        <Box
                          cursor={"pointer"}
                          color={"#115EFA"}
                          onClick={() => handleOrderPage(index)}
                        >
                          <MdEditSquare />
                        </Box>
                      </Box>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>

        <Box>
          <Button
            colorScheme="green"
            onClick={() => goToPreviousPage(currentPage - 1)}
            disabled={currentPage === 1}
            fontSize={"12px"}
          >
            &laquo; Anterior
          </Button>
          <Button
            colorScheme="green"
            ml={2}
            onClick={() => goToNextPage(currentPage + 1)}
            disabled={currentPage === lastPage}
            fontSize={"12px"}
          >
            Próxima &raquo;
          </Button>
        </Box>
      </Container>
    </>
  );
}
