import React, { useContext, useState, useEffect, useRef } from "react";
import Navbar from "../../components/Navbar/Navbar";
import {
  Box,
  Flex,
  Text,
  Stack,
  Container,
  FormControl,
  Select,
  Input,
  FormLabel,
  Switch,
  Button,
  Icon,
  useToast,
} from "@chakra-ui/react";
import JoditEditor from "../../components/Editor";
import { UserContext } from "../../contexts/User";
import { IoAddCircleOutline, IoTrashOutline } from "react-icons/io5";
import { OrderContext } from "../../contexts/Order";
import { editableInputTypes } from "@testing-library/user-event/dist/utils";

export default function Budget() {
  const { listClientsApi } = useContext(UserContext);
  const { createBudgetApi } = useContext(OrderContext);
  const toast = useToast();
  const [clients, setClients] = useState("");
  const [textAreaValue, setTextAreaValue] = useState("");
  const [inputs, setInputs] = useState([]);
  const fileInputRefs = useRef([]);
  const [title, setTitle] = useState("");
  const [client, setClient] = useState("");
  const [planID, setPlanID] = useState("");
  const [fastDelivery, setFastDelivery] = useState(false);
  const [fastDeliveryDays, setFastDeliveryDays] = useState("");
  const [planDays, setPlanDays] = useState();
  const [finalLanguage, setFinalLanguage] = useState("");
  const [magazineName, setMagazineName] = useState("");
  const [magazineEdit, setMagazineEdit] = useState("");
  const [numberWords, setNumberWords] = useState("");
  const [totalReference, setTotalReference] = useState(0);
  const [showAddClientForm, setShowAddClientForm] = useState(false);
  const [planRate, setPlanRate] = useState(0);
  const [totalBudget, setTotalBudget] = useState();
  const [totalBudgetPix, setTotalBudgetPix] = useState();
  const [fastDeliveryPrice, setFastDeliveryPrice] = useState();
  const [qtyReferences, setQtyReferences] = useState();
  const [totalFigures, setTotalFigures] = useState(0);
  const [totalWords, setTotalWords] = useState(0);
  const [qtyFigure, setQtyFigure] = useState(0);
  const [editionValue, setEditionValue] = useState(0);
  const [paymentLink, setPaymentLink] = useState("");
  const [precoParcelamento, setPrecoParcelamento] = useState("");

  const adicionarInput = () => {
    setInputs([...inputs, { type: "" }]);
  };
  const handleChange = (index) => (event) => {
    const newInputs = [...inputs];
    newInputs[index].file = event.target.files[0];
    setInputs(newInputs);
  };

  const excluirInput = (index) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };
  const handleInputTitle = (e) => setTitle(e.target.value);
  const handleInputClientID = (e) => setClient(e.target.value);

  const handleInputMagazineName = (e) => setMagazineName(e.target.value);
  const handleInputMagazineEdit = (e) => {
    setMagazineEdit(e.target.value);
    if (e.target.value != 0) {
      calculateEdition();
    }
  };
  const handleLanguageSelect = (e) => {
    setFinalLanguage(e.target.value);
  };
  const handleInputPaymentLink = (e) => setPaymentLink(e.target.value);
  const handleBlurAreaChange = (content) => {
    setTextAreaValue(content);
  };
  const handleInputReferencesChange = (e) => {
    const inputValue = e.target.value;
    setQtyReferences(e.target.value);
    calculateReferences(inputValue);
    if (fastDelivery) {
      setFastDeliveryDays("");
    }
  };
  async function listClients() {
    try {
      const clients = await listClientsApi();
      setClients(clients.data?.[0]);
      console.log(clients);
    } catch (error) {
      console.log(error);
    }
  }

  const handleSwitchChange = () => {
    setShowAddClientForm(!showAddClientForm);
  };

  const handleSwitchFastDelivery = () => {
    setFastDelivery(!fastDelivery);
    setFastDeliveryDays(0);
    setTotalBudget(0);
    setTotalBudgetPix(0);
    setTotalFigures(0);
    setMagazineEdit(0);
    setEditionValue(0);
    setTotalReference(0);
    setTotalWords(0);
    handleInputPlanID("0");
    if (fastDelivery) {
      setTotalBudgetPix(0);
      setTotalBudget(0);
      setNumberWords(0);
      setTotalWords(0);
      setQtyReferences(0);
      setMagazineEdit(0);
      setTotalFigures(0);
      setTotalReference(0);
    }
  };

  const handleInputPlanID = (e) => {
    if (e && e.target && e.target.value !== undefined) {
      const selectedPlan = e.target.value;
      setPlanID(selectedPlan);
      if (selectedPlan === "1") {
        setPlanRate(0.195);
        setFastDeliveryPrice(0.012);
        setPlanDays(17);
      } else if (selectedPlan === "2") {
        setPlanRate(0.231);
        setFastDeliveryPrice(0.135);
        setPlanDays(17);
      } else if (selectedPlan === "3") {
        setPlanRate(0.254);
        setFastDeliveryPrice(0.135);
        setPlanDays(17);
      }
    } else {
      const selectedPlan = "";
      setPlanID(selectedPlan);
      setPlanRate("");
      setFastDeliveryPrice("");
      setPlanDays("");
      setTotalBudget(0);
      setTotalBudgetPix(0);
      // Resetando valores
      setMagazineEdit("");
      setEditionValue(0);
      setTotalBudget(0);
    }

    // Defina a taxa com base no plano selecionado
  };

  function calculateEdition() {
    if (planID === "3") {
      setEditionValue(275);
    } else if (planID === "3" && fastDelivery == true) {
      setEditionValue(290);
    } else if (planID != "3" && fastDelivery == true) {
      setEditionValue(291.25);
    } else {
      setEditionValue(290);
    }
  }

  function calculatePricePlan(words) {
    setNumberWords(words);
    const resultWords = words * planRate;

    setTotalWords(resultWords);
  }

  function calculatePriceFastDelivery(references) {
    const diferenceDays = planDays - fastDeliveryDays;

    const priceFD = diferenceDays * fastDeliveryPrice;
    const priceWords = planRate + priceFD; // Assuming planRate is defined somewhere

    const totalWordsFD = numberWords * priceWords; // Assuming numberWords is defined somewhere

    const custoPorReferencia = 2.3;
    setQtyReferences(references);
    if (references > 50) {
      const referencesCost = custoPorReferencia * (qtyReferences - 50);
      setTotalReference(referencesCost);
    } else if (references <= 50) {
      setTotalReference(0);
    }

    setTotalBudgetPix(
      totalWordsFD + totalReference + totalFigures + editionValue
    );

    const total = totalWordsFD + totalReference + totalFigures + editionValue;
    const percentageToAdd = total * (3.5 / 100);
    const totalBudgetCalc = total + percentageToAdd;

    setTotalBudget(totalBudgetCalc);
  }

  function calculateReferences(qtdeReferencias) {
    const custoPorReferencia = 2.0;
    if (qtdeReferencias > 50) {
      const references = custoPorReferencia * (qtdeReferencias - 50);
      setTotalReference(references);
    } else {
      setTotalReference(0);
    }
  }

  function calculateFigure(qtdeFiguras) {
    if (!fastDelivery) {
      let custoPorFigura = 15.0;
      setQtyFigure(qtdeFiguras);
      setTotalFigures(custoPorFigura * qtdeFiguras);
    } else {
      let custoPorFigura = 30.0;
      setQtyFigure(qtdeFiguras);
      setTotalFigures(custoPorFigura * qtdeFiguras);
    }
  }
  function calculateTotal(
    totalWords,
    totalReference,
    totalFigures,
    editionValue
  ) {
    setTotalBudgetPix(
      totalWords + totalReference + totalFigures + editionValue
    );

    const total = totalWords + totalReference + totalFigures + editionValue;
    const percentageToAdd = total * (3.5 / 100);
    const totalBudgetCalc = total + percentageToAdd;

    setTotalBudget(totalBudgetCalc);
  }

  const handleGenerateData = async () => {
    const data = {
      text_area_value: textAreaValue,
      title: title,
      client_id: client,
      plan_id: planID,
      fast_delivery: fastDelivery,
      fast_delivery_days: fastDeliveryDays,
      plan_days: planDays,
      final_language: finalLanguage,
      magazine_name: magazineName,
      magazine_edit: magazineEdit,
      number_words: numberWords,
      total_reference: totalReference,
      plan_rate: planRate,
      total_budget: totalBudget,
      total_budget_pix: totalBudgetPix,
      fast_delivery_price: fastDeliveryPrice,
      qty_references: qtyReferences,
      total_figures: totalFigures,
      total_words: totalWords,
      qty_figure: qtyFigure,
      edition_value: editionValue,
      payment_link: paymentLink,
      language_final: finalLanguage,
    };

    try {
      const responseData = await createBudgetApi(data);

      if (responseData.status === 202) {
        // Faça algo com a resposta do servidor
        toast({
          title:
            "Serviço Criado, mas precisará selecionar o expert manualmente",
          status: "warning",
          duration: 7000,
          isClosable: true,
        });
      }
      console.log(responseData);
      // Redirecione para a página de serviços
      window.location.pathname = "/servicos";
    } catch (error) {
      toast({
        title:
          "Não foi possível criar o serviço, verifique os campos e tente novamente",
        status: "error",
        duration: 7000,
        isClosable: true,
      });
    }
    console.log(data); // Exibir o objeto JSON no console para depuração
  };

  useEffect(() => {
    listClients();
  }, []);

  useEffect(() => {
    fileInputRefs.current = fileInputRefs.current.slice(0, inputs.length);
  }, [inputs]);

  return (
    <>
      <Navbar />
      <Container
        position={"absolute"}
        top={120}
        ml={270}
        maxWidth={"80vw"}
        py={5}
      >
        <Box>
          {showAddClientForm ? (
            <Box
              bg={"#ffff"}
              borderRadius={"15px "}
              boxShadow={"0px 7px 16px 4px #EAEAEA"}
              p={5}
              maxWidth={"100%"}
              mb={5}
            >
              <Text fontSize={"18px"} my={5}>
                Adicionar novo cliente
              </Text>
              {/* Formulário para adicionar novo cliente */}
              <FormControl my={2}>
                <FormLabel>Nome</FormLabel>
                <Input placeholder="Nome" />
              </FormControl>
              <FormControl my={2}>
                <FormLabel>Email</FormLabel>
                <Input type="email" placeholder="Email" />
              </FormControl>
              <FormControl my={2}>
                <FormLabel>Telefone</FormLabel>
                <Input placeholder="Telefone" />
              </FormControl>
              <FormControl my={2}>
                <FormLabel>Endereço</FormLabel>
                <Input placeholder="Endereço" />
              </FormControl>
              <FormControl my={2}>
                <FormLabel>Empresa</FormLabel>
                <Input placeholder="Empresa" />
              </FormControl>
            </Box>
          ) : (
            ""
          )}
        </Box>
        <Box
          bg={"#ffff"}
          borderRadius={"15px "}
          boxShadow={"0px 7px 16px 4px #EAEAEA"}
          p={5}
          maxWidth={"100%"}
        >
          <FormControl display="flex" alignItems="center" pb={2}>
            <FormLabel mb="0">Adicionar novo cliente ?</FormLabel>
            <Switch onChange={handleSwitchChange} />
          </FormControl>

          <FormControl display="inline-flex" my={2}>
            {clients && (
              <Select
                onChange={handleInputClientID}
                placeholder="Selecione um cliente"
              >
                {clients.map((index) => (
                  <option key={index.userID} value={index.userID}>
                    {index.name}
                  </option>
                ))}
              </Select>
            )}
          </FormControl>
          <FormControl display="inline-flex">
            <Select
              value={planID}
              onChange={handleInputPlanID}
              placeholder="Selecione um plano"
            >
              <option value={1}>Basic</option>
              <option value={2}>Pro</option>
              <option value={3}>Full</option>
            </Select>

            <Select
              onChange={handleLanguageSelect}
              placeholder="Selecione a língua do texto "
              marginLeft="1%"
            >
              <option value={"pt"}>Português </option>
              <option value={"en"}>Inglês</option>
            </Select>

            <Select
              onChange={handleLanguageSelect}
              placeholder="Selecione o Tipo de Serviço "
              marginLeft="1%"
            >
              <option value={"traducao"}>Tradução </option>
              <option value={"revisao"}>Revisão</option>
            </Select>
          </FormControl>
          <FormControl my={2} display={"flex"} flexDirection={"row"}>
            <Box>
              <Text>O preço por palavra desse serviço será:</Text>
              <Input
                type="number"
                value={planRate}
                onChange={(e) => setPlanRate(e.target.value)}
              />
            </Box>

            <Box ml={5}>
              <Text>Coloque a quantidade de palavras</Text>
              <Input
                value={numberWords}
                type="number"
                onChange={(e) => calculatePricePlan(e.target.value)}
              />
            </Box>
            <Box ml={5}>
              <Text>Coloque a quantidade de referências</Text>
              <Input
                type="number"
                onChange={(e) => handleInputReferencesChange(e)}
              />
            </Box>
            <Box ml={5}>
              <Text>Coloque a quantidade de figuras</Text>
              <Input
                type="number"
                onChange={(e) => calculateFigure(e.target.value)}
              />
            </Box>
            <Box ml={5}>
              <FormLabel mb="0">O serviço é FastDelivery?</FormLabel>
              <Switch onChange={handleSwitchFastDelivery} />
            </Box>
          </FormControl>

          <FormControl marginY="2%">
            <FormLabel>Edição das normas da Revista</FormLabel>
            <Select
              display="inline-block"
              width={"40%"}
              value={magazineEdit}
              placeholder="Edição das normas da Revista"
              onChange={handleInputMagazineEdit}
            >
              <option value="1">Sim</option>
              <option value="0">Não</option>
            </Select>
            {magazineEdit == 1 ? (
              <>
                <FormControl marginTop="1%" display="inline-flex">
                  <Input
                    display="inline-block"
                    placeholder="Nome da Revista"
                    onChange={handleInputMagazineName}
                  ></Input>
                </FormControl>
              </>
            ) : (
              ""
            )}
          </FormControl>

          {fastDelivery === true ? (
            <>
              <FormControl my={2} display={"flex"} flexDirection={"col"}>
                <Box>
                  <Text>Quantidade de dias do plano </Text>
                  <Input
                    type="number"
                    value={planDays}
                    onChange={(e) => setPlanDays(e.target.value)}
                  />
                </Box>
              </FormControl>
              <FormControl my={2} display={"flex"} flexDirection={"row"}>
                <Box>
                  <Text>Em quantos dias deverá ser entregue? </Text>
                  <Input
                    type="number"
                    onBlur={(e) => {
                      setFastDeliveryDays(e.target.value);
                      calculatePriceFastDelivery(qtyReferences);
                    }}
                  />
                </Box>
              </FormControl>

              <Box>
                {" "}
                <Button
                  colorScheme="blue"
                  onClick={() =>
                    calculatePriceFastDelivery(qtyReferences, qtyFigure)
                  }
                >
                  Calcular total Fast Delivery
                </Button>
              </Box>
            </>
          ) : (
            <Box>
              {" "}
              <Button
                colorScheme="blue"
                onClick={() =>
                  calculateTotal(
                    totalWords,
                    totalReference,
                    totalFigures,
                    editionValue,
                    totalBudget
                  )
                }
              >
                Calcular total
              </Button>
            </Box>
          )}

          <FormControl display="inline-flex" marginTop="2%">
            <Text>Adicione aqui o link de pagamento </Text>
            <Input
              value={paymentLink}
              onChange={handleInputPaymentLink}
              placeholder="Adicione o link de pagamento"
            />
          </FormControl>
          <FormControl display="block" marginTop="2%">
            <Text>Adicione aqui o titulo do Serviço </Text>
            <Input onChange={handleInputTitle} placeholder="Titulo" />
          </FormControl>
          <Stack margin="15px 0">
            <Text>Adicione aqui o Abstract </Text>
          </Stack>
          <FormControl>
            <JoditEditor onBlur={handleBlurAreaChange} value={textAreaValue} />
          </FormControl>

          {totalBudget > 0 && totalBudgetPix > 0 ? (
            <>
              <Box my={5}>
                Orçamento Total: R$ {totalBudget?.toFixed(2).replace(".", ",")}{" "}
                (Cartão de crédito)
              </Box>
              <Box my={5}>
                Orçamento Total: R${" "}
                {totalBudgetPix?.toFixed(2).replace(".", ",")} (PIX)
              </Box>
            </>
          ) : (
            ""
          )}
          {totalBudget > 0 && totalReference > 0 ? (
            <Box my={2} fontSize={"14px"}>
              Orçamento Referências: R${" "}
              {totalReference.toFixed(2).replace(".", ",")}
            </Box>
          ) : (
            ""
          )}
          {totalBudget > 0 && totalFigures > 0 ? (
            <Box my={2} fontSize={"14px"}>
              Orçamento Figuras: R$ {totalFigures.toFixed(2).replace(".", ",")}
            </Box>
          ) : (
            ""
          )}
          {totalBudget > 0 && editionValue > 0 ? (
            <Box my={2} fontSize={"14px"}>
              Orçamento Edição das Normas: R${" "}
              {editionValue.toFixed(2).replace(".", ",")}
            </Box>
          ) : (
            ""
          )}

          {totalBudget > 0 ? (
            <Button colorScheme="green" my="5" onClick={handleGenerateData}>
              {" "}
              Enviar Orçamento
            </Button>
          ) : (
            ""
          )}
        </Box>
      </Container>
          
    </>
  );
}
