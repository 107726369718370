import React, { useContext, useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import {
  Box,
  Container,
  Flex,
  Text,
  Spacer,
  useDisclosure,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { OrderContext } from "../../contexts/Order";

import { MdEditSquare, MdRemoveRedEye } from "react-icons/md";

export default function Budgets() {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const { listBudgetsApi, approveBudgetApi } = useContext(OrderContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [orders, setOrders] = useState([]);
  const [orderPreview, setOrderPreview] = useState();
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [selectedOrderId, setSelectedOrderId] = useState(null);

  function limitToTwoWords(text) {
    return text;
  }

  // Função para carregar pedidos com base na página fornecida
  async function loadOrders(page) {
    try {
      const ordersApi = await listBudgetsApi(page);

      console.log(ordersApi?.data);
      setOrders(ordersApi?.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    loadOrders(currentPage);
  }, [currentPage]);

  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  }

  function goToNextPage() {
    if (currentPage < lastPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }

  function previewOrder(index) {
    if (isPreviewing) {
      return;
    }

    setIsPreviewing(true);
    setOrderPreview(index);

    onOpen();

    setTimeout(() => {
      setIsPreviewing(false);
    }, 1000); // Aguardar 1 segundo antes de permitir uma nova chamada
  }

  function approveBudget(orderId) {
    setSelectedOrderId(orderId);
    onOpen();
  }

  return (
    <>
      <Navbar />

      <Container
        position={"absolute"}
        top={120}
        ml={{
          base: 0, // Remove a margem em telas menores (base)
          md: 270, // Define a margem em telas maiores (md)
        }}
        minWidth={"78vw"}
      >
        <Box
          bg={"#ffff"}
          borderRadius={"15px"}
          boxShadow={"0px 7px 16px 4px #EAEAEA"}
        >
          <Flex display={"flex"} padding={5} alignItems="center">
            <Box>
              <Text fontSize={"24px"} fontWeight={"600"}>
                Serviços
              </Text>
            </Box>

            <Spacer />

            <>
              <Box>
                <Button
                  colorScheme="green"
                  fontSize={"14px"}
                  _hover={{
                    backgroundColor: "#33BA9D",
                  }}
                >
                  <Link to="/criar/orcamento"> Criar Orçamento </Link>
                </Button>
              </Box>
            </>
          </Flex>
        </Box>

        <Box
          backgroundColor={"white"}
          mt={5}
          boxShadow={"0px 8px 16px -10px"}
          borderRadius={"8px"}
          marginBottom={5}
        >
          <TableContainer>
            <Table fontSize={"12px"}>
              <Thead css={{ fontFamily: "Poppins", fontSize: "12px" }}>
                <Tr>
                  <Th>ID</Th>
                  <Th>Titulo</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>
              <Tbody color={"#888686"} fontWeight={"300"}>
                {orders.length > 0 &&
                  orders.map((index) => (
                    <Tr key={index.id}>
                      <Td>{index.id}</Td>
                      <Td>{index.title}</Td>
                      <Td>
                        {index.status === 0 ? "Não Aprrovado" : "Aprovado"}
                      </Td>
                      <Td>
                        {index.status === 0 ? (
                          <Button
                            colorScheme="green"
                            size="sm"
                            onClick={() => approveBudget(index.id)}
                          >
                            Aprovar
                          </Button>
                        ) : (
                          "Aprovado"
                        )}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Container>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmar Aprovação</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Você quer mesmo aprovar este orçamento?</ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                approveBudgetApi(selectedOrderId);
                onClose();
              }}
            >
              Sim
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
