import React, { useState, useContext, useEffect } from "react";
import JoditEditor from "../../components/Editor";
import {
  useToast,
  Box,
  Button,
  Text,
  Input,
  Stack,
  FormControl,
  Tabs,
  Tab,
  TabList,
  Select,
  Tooltip,
  Badge,
} from "@chakra-ui/react";
import { OrderContext } from "../../contexts/Order";
import { ProlabContext } from "../../contexts/Prolab";
import {
  AiOutlineDislike,
  AiOutlineLike,
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineSave,
} from "react-icons/ai";

export default function TeachingPills() {
  const toast = useToast();
  const [title, setTitle] = useState("");
  const {
    listPillsProlabApi,
    createPillProlabApi,
    updatePillApi,
    deletePillApi,
  } = useContext(ProlabContext);
  const [description, setDescription] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [editingPillId, setEditingPillId] = useState(null);
  const [categories, setCategories] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(1);
  const [categoryId, setCategoryId] = useState(1);
  const { feedbackCategoriesApi } = useContext(OrderContext);
  const [pills, setPills] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [tempTitle, setTempTitle] = useState("");
  const [tempDescription, setTempDescription] = useState("");

  function limitToTwoWords(text) {
    const words = text.split(" ");
    if (words.length > 2) {
      return words.slice(0, 2).join(" ") + "..."; // Adiciona '...' se tiver mais de 2 palavras
    }
    return text;
  }

  const handleEditClick = (pill) => {
    setIsEditing(true);
    setTempTitle(pill.learning_pills);
    setTempDescription(pill.description);
    setEditingPillId(pill.id);
  };

  const handleSaveClick = async () => {
    const updatedPill = {
      id: editingPillId,
      learning_pills: tempTitle,
      description: tempDescription,
    };

    try {
      const response = await updatePillApi(updatedPill);
      if (response.status === 200) {
        const updatedPills = pills.map((pill) => {
          if (pill.id === editingPillId) {
            return updatedPill;
          }
          return pill;
        });
        setPills(updatedPills);
        toast({
          title: "Pílula atualizada com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        listPills();
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Erro ao atualizar a pílula",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    setIsEditing(false);
    setEditingPillId(null);
  };

  const deletePill = async (pillId) => {
    try {
      const response = await deletePillApi(pillId);
      if (response.status === 200) {
        const updatedPills = pills.filter((pill) => pill.id !== pillId);
        setPills(updatedPills);
        toast({
          title: "Pílula deletada com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        listPills();
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Erro ao deletar a pílula",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  async function listPills() {
    try {
      const response = await listPillsProlabApi(categoryId);
      console.log(response);
      setPills(response.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  async function getCategoriesApi(id) {
    try {
      const response = await feedbackCategoriesApi(id);
      console.log(response.data?.[0]);
      setCategories(response.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleDescriptionChange = (content) => {
    setDescription(content);
  };

  const saveInfo = async () => {
    //alert (selectedCategory)

    const data = {
      pill: title,
      description: description,
      category_id: selectedCategory,
    };

    try {
      const response = await createPillProlabApi(data);
      console.log(response);
      setTitle("");
      setDescription("");

      if (response.status === 200) {
        // Faça algo com a resposta do servidor
        toast({
          title: "Pílula criada com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        listPills();
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function listPillsCategory(id) {
    try {
      const response = await listPillsProlabApi(id);
      console.log(response);
      setPills(response.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setCategoryId(category.id);
    setCategoryName(category.category);

    listPillsCategory(category.id);
  };
  useEffect(() => {
    setTimeout(() => {
      getCategoriesApi();
    }, 1000); // Ag

    listPills();
  }, []);

  return (
    <>
      {categories && (
        <>
          <Tabs
            variant="soft-rounded"
            colorScheme="blackAlpha"
            backgroundColor="white"
          >
            <Box
              w={"250px"}
              justifyContent={"center"}
              mt={"370px"}
              display={"block"}
              position={"absolute"}
              left={"-15px"}
              backgroundColor={"#ffff"}
              p={8}
              borderRadius={"15px "}
              boxShadow={"0px 7px 16px 4px #EAEAEA"}
            >
              <Text>Categorias</Text>
              <TabList display={"block"} borderBottom={"0"}>
                {categories.map((index) => (
                  <Tab
                    mt={"1.5px"}
                    fontSize={"14px"}
                    _hover={{ backgroundColor: "black", color: "white" }}
                    _selected={{ backgroundColor: "black", color: "white" }}
                    onClick={() => handleCategoryClick(index)}
                  >
                    {limitToTwoWords(index.category)}
                  </Tab>
                ))}
              </TabList>
            </Box>
          </Tabs>

          <Box marginTop={"110px"}>
            {pills &&
              pills.map((index) => (
                <Box
                  key={index.id}
                  margin={"20px"}
                  bg={"#ffff"}
                  borderRadius={"15px "}
                  boxShadow={"0px 7px 16px 4px #EAEAEA"}
                  p={5}
                  width={"75%"}
                  ml={"250px"}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    borderBottom={"1px"}
                    borderColor={"#D2D1D166"}
                  >
                    <Text fontSize={"20px"}>Pílula Expert</Text>

                    <Box
                      m={5}
                      gap={3}
                      display={"flex"}
                      justifyContent={"end"}
                      alignItems={"center"}
                    ></Box>
                  </Box>

                  <Box
                    mt={"20px"}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"flex-start"}
                  >
                    <Text
                      p={2}
                      borderRadius={"20px"}
                      background={"black"}
                      color={"white"}
                      fontSize={"14px"}
                    >
                      {index.category_name}{" "}
                    </Text>
                  </Box>
                  <Box>
                    {index?.tags?.map((tag, tagIndex) => (
                      <Badge
                        key={tagIndex}
                        colorScheme="green"
                        borderRadius="full"
                        mt={2}
                        mr={2}
                      >
                        {tag.category_name}
                      </Badge>
                    ))}
                  </Box>
                  <Box mt={"20px"} mb={"15px"}>
                    {isEditing && editingPillId === index.id ? (
                      <Input
                        value={tempTitle}
                        onChange={(e) => setTempTitle(e.target.value)}
                      />
                    ) : (
                      <Text fontSize={"24px"}>{index.learning_pills}</Text>
                    )}
                  </Box>
                  <Box>
                    {isEditing && editingPillId === index.id ? (
                      <JoditEditor
                        value={tempDescription}
                        onChange={(value) => setTempDescription(value)}
                      />
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: index.description,
                        }}
                      />
                    )}
                  </Box>

                  {/*
                    <Box display={'flex'} mt={'20px'} alignItems={'center'} gap={1}>
                        <Text fontSize={'16px'} color={'#888686'}>Essa dica foi útil?</Text>
                        <AiOutlineLike fontSize={'16px'} color={'#888686'} />
                        <Text fontSize={'16px'} color={'#888686'}>Sim</Text>
                        <AiOutlineDislike fontSize={'16px'} color={'#888686'} />
                        <Text fontSize={'16px'} color={'#888686'}>Não</Text>
                        
                    </Box>
                    */}
                </Box>
              ))}
          </Box>
        </>
      )}
    </>
  );
}
