import React, { useContext, useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Flex,
  Input,
  Text,
  Spacer,
  useDisclosure,
  Button,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/react";
import { OrderContext } from "../../contexts/Order";
import { LuPiggyBank } from "react-icons/lu";

export default function Payments() {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const { listOrdersApi, listOrdersExpertApi } = useContext(OrderContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [orders, setOrders] = useState([]);
  const [orderPreview, setOrderPreview] = useState();
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  async function listOrders(page) {
    try {
      const ordersApi = await listOrdersApi(page);
      setOrders(ordersApi?.data?.[0]?.data || []);
      setLastPage(ordersApi?.data?.[0]?.last_page || 1);
    } catch (error) {
      console.log(error);
    }
  }

  async function listOrdersExpert(page) {
    try {
      const ordersApi = await listOrdersExpertApi(page);
      setOrders(ordersApi?.data?.[0]?.data || []);
      setLastPage(ordersApi?.data?.[0]?.last_page || 1);
      console.log(orders);
    } catch (error) {
      console.log(error);
    }
  }
  function handleOrderPage(index) {
    localStorage.setItem("orderID", index.orderID);
    if (user.role_id === 1) {
      navigate(`/servico/${index.title}`);
    } else {
      navigate(`/expert/servico/${index.title}`);
    }
  }

  function previewOrder(index) {
    if (isPreviewing) {
      return;
    }

    setIsPreviewing(true);
    setOrderPreview(index);
    console.log(index);

    onOpen();

    setTimeout(() => {
      setIsPreviewing(false);
    }, 1000); // Aguardar 1 segundo antes de permitir uma nova chamada
  }
  useEffect(() => {
    if (user.role_id === 1) {
      listOrders(currentPage);
    }
    if (user.role_id === 2) {
      listOrdersExpert(currentPage);
    }

    // Delay: Set a delay before making the next request
    const delay = 1000; // 1 second
    const timer = setTimeout(() => {
      if (currentPage < lastPage) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }, delay);

    // Clean up the timer on component unmount
    return () => clearTimeout(timer);
  }, [currentPage]);

  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  }

  function goToNextPage() {
    if (currentPage < lastPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }

  return (
    <>
      <Navbar />

      <Container position={"absolute"} top={120} ml={270} minWidth={"78vw"}>
        <Box
          bg={"#ffff"}
          borderRadius={"15px"}
          boxShadow={"0px 7px 16px 4px #EAEAEA"}
        >
          <Flex display={"flex"} padding={5} alignItems="center">
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <LuPiggyBank fontSize={"20px"} />
              <Text fontSize={"20px"} fontWeight={"600"}>
                Pagamentos
              </Text>
            </Box>
            <Spacer />

            <Box>
              <Button mr={"5px"}>Limpar filtros </Button>
              <Button colorScheme="green" fontSize={"14px"}>
                <Link to="/pagamentos/comprovantes/expert">
                  {" "}
                  Novo pagamento{" "}
                </Link>
              </Button>
            </Box>
          </Flex>
          <Text marginLeft={"20px"}>Buscas e Filtros</Text>
          <Flex>
            <Box
              m={"10px"}
              p={"10px"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={2}
            >
              <FormControl>
                <FormLabel fontSize={"12px"} fontWeight={400} color={"#505050"}>
                  Pesquisar
                </FormLabel>
                <Input placeholder="Digite o que deseja" />
              </FormControl>
              <FormControl>
                <FormLabel fontSize={"12px"} fontWeight={400} color={"#505050"}>
                  Status
                </FormLabel>
                <Select
                  fontWeight={400}
                  color={"#505050"}
                  placeholder="Selecione o status"
                >
                  <option>1</option>
                  <option>2</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel fontSize={"12px"} fontWeight={400} color={"#505050"}>
                  Data de conclusão
                </FormLabel>
                <Select
                  fontWeight={400}
                  color={"#505050"}
                  placeholder="Selecione a data"
                >
                  <option>1</option>
                  <option>2</option>
                </Select>
              </FormControl>
            </Box>
          </Flex>
        </Box>

        <Box
          backgroundColor={"white"}
          mt={5}
          boxShadow={"0px 8px 16px -10px"}
          borderRadius={"8px"}
          marginBottom={5}
        >
          <TableContainer>
            <Table fontSize={"12px"}>
              <Thead css={{ fontFamily: "Poppins", fontSize: "12px" }}>
                <Tr>
                  <Th>ID</Th>
                  <Th>Titulo</Th>
                  <Th>Responsabilidade</Th>
                  <Th>Status</Th>
                  <Th>Data de Conclusão</Th>
                  <Th>Tipo</Th>
                  <Th>Arquivo</Th>
                </Tr>
              </Thead>
              <Tbody color={"#888686"} fontWeight={"300"}>
                <Tr>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <Box>
          <Button
            colorScheme="green"
            onClick={() => goToPreviousPage(currentPage - 1)}
            disabled={currentPage === 1}
            fontSize={"12px"}
          >
            &laquo; Previous
          </Button>
          <Button
            colorScheme="green"
            ml={2}
            onClick={() => goToNextPage(currentPage + 1)}
            disabled={currentPage === lastPage}
            fontSize={"12px"}
          >
            Next &raquo;
          </Button>
        </Box>
      </Container>
    </>
  );
}
