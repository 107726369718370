import React, { useContext, useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Container,
  Flex,
  Input,
  Text,
  Spacer,
  Textarea,
  Button,
  FormControl,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import { PaymentContext } from "../../contexts/Payments";
import { LuPiggyBank } from "react-icons/lu";

export default function Payments() {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const toast = useToast();
  const { paymentInfoApi, updatePaymentApi } = useContext(PaymentContext);
  const { id } = useParams();
  const [paymentsinfo, setPaymentsInfo] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState("");
  const [value, setValue] = useState();
  const [paid, setPaid] = useState("");
  const [description, setDescription] = useState("");
  const [editedDescription, setEditedDescription] = useState("");

  const handleValueChange = (e) => {
    setEditedValue(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setEditedDescription(e.target.value);
  };

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handlePayment = async () => {
    setValue(`R$${editedValue}`);
    setDescription(editedDescription);
    setPaid("Recebido");

    const data = {
      value: editedValue,
      description: editedDescription,
      paid: 1,
    };

    try {
      const response = await updatePaymentApi(id, data);
      if (response.status === 200) {
        toast({
          title: "Pagamento confirmado e informações atualizadas!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        paymentInfo();
      } else {
        throw new Error("Erro na confirmação");
      }
    } catch (error) {
      toast({
        title: "Erro ao confirmar pagamento",
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }
    setIsEditing(false);
  };
  async function paymentInfo() {
    try {
      const response = await paymentInfoApi(id);
      setPaymentsInfo(response?.data?.[0]);
      console.log(response?.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    paymentInfo();
  }, []);

  return (
    <>
      <Navbar />

      <Container position={"absolute"} top={120} ml={270} minWidth={"78vw"}>
        <Box
          bg={"#ffff"}
          borderRadius={"15px"}
          boxShadow={"0px 7px 16px 4px #EAEAEA"}
        >
          <Flex display={"flex"} padding={5} alignItems="center">
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <LuPiggyBank fontSize={"20px"} />
              <Text fontSize={"20px"} fontWeight={"600"}>
                Pagamentos
              </Text>
            </Box>
            <Spacer />

            <Box>
              <Button fontSize={"14px"} colorScheme="green">
                <Link to="/pagamentos"> Voltar para pagamentos </Link>
              </Button>
            </Box>
          </Flex>
        </Box>

        <Box
          backgroundColor={"white"}
          mt={5}
          boxShadow={"0px 8px 16px -10px"}
          borderRadius={"8px"}
          marginBottom={5}
        >
          <Box p={"20px"} borderBottom={"1px"} borderColor={"#DBDBDB"}>
            <Text>Enviar comprovante de pagamento</Text>
          </Box>
          {paymentsinfo && (
            <>
              <Box p={"20px"} display={"flex"} gap={5}>
                <FormControl marginBottom={"15px"}>
                  <FormLabel fontSize={"12px"} fontWeight={400}>
                    ID
                  </FormLabel>
                  <Text> {paymentsinfo?.id}</Text>
                </FormControl>
                <FormControl marginBottom={"15px"}>
                  <FormLabel fontSize={"12px"} fontWeight={400}>
                    Expert
                  </FormLabel>
                  <Text>{paymentsinfo?.user?.name}</Text>
                </FormControl>
                <FormControl marginBottom={"15px"}>
                  <FormLabel fontSize={"12px"} fontWeight={400}>
                    Status
                  </FormLabel>
                  <Text>
                    {paymentsinfo?.paid === 0 ? "Não recebido" : "Recebido"}
                  </Text>
                </FormControl>

                <FormControl marginBottom={"15px"}>
                  <FormLabel fontSize={"12px"} fontWeight={400}>
                    Data de Pagamento
                  </FormLabel>
                  <Text>{paymentsinfo?.updated_at}</Text>
                </FormControl>
                <FormControl marginBottom={"15px"}>
                  <FormLabel fontSize={"12px"} fontWeight={400}>
                    Valor
                  </FormLabel>
                  {isEditing ? (
                    <Input
                      value={editedValue}
                      onChange={handleValueChange}
                      placeholder={value}
                    />
                  ) : (
                    <Text>{paymentsinfo?.value_to_receipt}</Text>
                  )}
                </FormControl>
                <FormControl marginBottom={"15px"}>
                  <Button onClick={toggleEdit}>Editar dados</Button>
                </FormControl>
              </Box>
              <Box p={"20px"}>
                <FormControl marginBottom={"15px"}>
                  <FormLabel fontSize={"12px"} fontWeight={400}>
                    Descrição
                  </FormLabel>
                  {isEditing ? (
                    <Textarea
                      value={editedDescription}
                      onChange={handleDescriptionChange}
                      placeholder={description}
                    />
                  ) : (
                    <Text>{paymentsinfo?.description_payment}</Text>
                  )}
                </FormControl>
              </Box>
              <Box p={"20px"} display={"flex"} alignItems={"center"} gap={5}>
                <FormControl marginBottom={"15px"}>
                  <FormLabel fontSize={"12px"} fontWeight={400}>
                    Comprovante de pagamento - Envie arquivos nas extensões de
                    PDF.
                  </FormLabel>
                  <Input type="file"></Input>
                </FormControl>
              </Box>
            </>
          )}

          <Box display={"flex"} justifyContent={"center"} p={"20px"}>
            <Button
              fontSize={"14px"}
              colorScheme="green"
              onClick={handlePayment}
            >
              Pagar
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
}
