import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  Button,
  Container,
  Link,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";

import { LuSheet } from "react-icons/lu";
import { FaRegListAlt } from "react-icons/fa";
import { UserContext } from "../../contexts/User";
export default function Agenda() {
  const { id } = useParams();
  const { listScheduleExpert, insertScheduleExpertApi } =
    useContext(UserContext);
  const toast = useToast();
  // Estado para armazenar eventos
  const [events, setEvents] = useState([]);
  const [eventName, setEventName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  async function listSchedule() {
    try {
      const response = await listScheduleExpert(id);
      setEvents(response.data?.[0]);

      console.log(response?.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    listSchedule();
  }, []);

  // Função para salvar o evento
  const saveEvent = async () => {
    const data = {
      user_id: id,
      period_start: startDate,
      period_end: endDate,
      title: eventName,
    };
    try {
      const response = await insertScheduleExpertApi(data, id);
      if (response.status === 200) {
        toast({
          title: "Agenda inserida com Sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }

      setEvents([
        ...events,
        {
          eventName,
          startDate,
          endDate,
        },
      ]);

      listSchedule();
    } catch (error) {
      toast({
        title: "Deu algo errado, verifique os campos e tente novamente",
        status: "error",
        isClosable: true,
      });
      console.log(error);
    }
    setEventName("");
    setStartDate("");
    setEndDate("");
  };

  return (
    <>
      <Box
        w={"75%"}
        backgroundColor={"white"}
        borderRadius={"8px 8px"}
        boxShadow={"0px 8px 16px -10px"}
        marginTop={"100px"}
        marginLeft={"250px"}
      >
        <Box
          w={"100%"}
          display={"flex"}
          marginTop={"20px"}
          alignItems={"center"}
          justifyContent={"space-between"}
          borderBottom={"1px"}
          borderColor={"#B1B1B1"}
          p={4}
        >
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <LuSheet color={"#2AD67E"} fontSize={"24px"} />
            <Text fontWeight={400} fontSize={"20px"}>
              Minha agenda
            </Text>
          </Box>
          <Button colorScheme="green" onClick={saveEvent}>
            Salvar Agenda
          </Button>
        </Box>

        <Box display={"block"} marginTop={"20px"} gap={2} p={2}>
          <Text fontSize={"20px"} fontWeight={400} mb={"20px"}>
            Definir agenda
          </Text>
          <Text fontSize={"16px"} fontWeight={400}>
            Defina a data em que você não estará disponível para prestar
            serviço.
          </Text>
        </Box>
        <Box display={"flex"} p={5} gap={2}>
          <FormControl>
            <FormLabel fontSize={"12px"} fontWeight={400}>
              Nome evento
            </FormLabel>
            <Input
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel fontSize={"12px"} fontWeight={400}>
              Data de início
            </FormLabel>
            <Input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel fontSize={"12px"} fontWeight={400}>
              Data de encerramento
            </FormLabel>

            <Input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </FormControl>
        </Box>
      </Box>

      <Flex
        w={"75%"}
        backgroundColor={"white"}
        borderRadius={"8px 8px"}
        boxShadow={"0px 8px 16px -10px"}
        marginLeft={"250px"}
        marginTop={"15px"}
      >
        <Box w={"100%"} p={5}>
          <Text borderBottom={"1px"} borderColor={"#B1B1B1"} fontSize={"20px"}>
            Histórico
          </Text>
          {/* Lista de eventos */}
          {events?.map((event, index) => (
            <Box
              key={index}
              display={"flex"}
              justifyContent={"space-between"}
              p={5}
            >
              <Box>
                <Text fontSize={"12px"} fontWeight={400} color={"#505050"}>
                  Nome do evento:
                </Text>
                <Text>{event.title}</Text>
              </Box>
              <Box display={"flex"}>
                <Box marginRight={"15px"}>
                  <Text fontSize={"12px"} fontWeight={400} color={"#505050"}>
                    Data de Início:
                  </Text>
                  <Text> {event.period_start}</Text>
                </Box>
                <Box>
                  <Text fontSize={"12px"} fontWeight={400} color={"#505050"}>
                    Data de Encerramento:
                  </Text>
                  <Text> {event.period_end}</Text>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Flex>
    </>
  );
}
